import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { IFaq, IUserDetailed, NewPanel, QuickFaqName } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { QuickFaqService } from '@app/core/state/faq';
import { UserService } from '@app/core/user-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { DesktopRightsidebarService } from './desktop-right-sidebar.service';

@Component({
  selector: 'app-desktop-right-sidebar-new',
  templateUrl: './desktop-right-sidebar.component.html',
  styleUrls: ['./desktop-right-sidebar.component.scss'],
  providers: [DesktopRightsidebarService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopRightSidebarNewComponent {
  readonly isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #sidebarService = inject(DesktopRightsidebarService);
  readonly #quickFaqService = inject(QuickFaqService);
  readonly #userService = inject(UserService);
  readonly #authService = inject(AuthService);

  readonly PanelEnum = NewPanel;

  readonly user$: Observable<IUserDetailed | null | undefined> = this.#userService.user$;
  readonly panels$: Observable<NewPanel[]> = this.#sidebarService.panels$;
  readonly isSomeItemsSelected$: Observable<boolean> = this.#sidebarService.isSomeItemsSelected$;
  readonly quickFaq$: Observable<IFaq | undefined> = this.#quickFaqService.quickFaq$(QuickFaqName.MARKET);

  closeAllPanels(): void {
    this.#sidebarService.closeAllPanels();
  }
  isPanelOpen(panels: NewPanel[] | null): boolean {
    return !!panels && (panels.includes(NewPanel.CHAT) || panels.includes(NewPanel.TRADES));
  }
  openAuthModal(): void {
    this.#authService.openLoginProvidersModal();
  }
  currentPanel(panels: NewPanel[]): NewPanel {
    if (panels.includes(NewPanel.TRADES)) {
      return NewPanel.TRADES;
    }
    return panels[0];
  }
  closeQuickFaq(name: string): void {
    this.#quickFaqService.setStatus({ menuName: name as QuickFaqName, wasClosed: true });
  }
}
