import { SocialItem, SocialType } from '@dev-fast/types';

export const LOGIN_PROVIDERS: SocialItem[] = [
  { name: SocialType.Steam, title: 'Steam' },
  // { name: SocialType.MetaMask, title: 'MetaMask' },
  { name: SocialType.Google, title: 'Google' },
  { name: SocialType.Vkontakte, title: 'VK' },
  // { name: SocialType.Twitter, title: 'Twitter' },
  { name: SocialType.Facebook, title: 'Facebook' },
  // { name: SocialType.Yandex, title: 'Yandex' },
  { name: SocialType.Twitch, title: 'Twitch' },
  { name: SocialType.Telegram, title: 'Telegram' },
];
export const SOCIALS_MEDIA: SocialItem[] = [
  { name: SocialType.Facebook, svgIcon: 'facebook', link: 'https://www.facebook.com/csgofastcom' },
  { name: SocialType.Youtube, svgIcon: 'youtube-btn', link: 'https://www.youtube.com/channel/UC8W2x0NiTj0hGAza6RuCJIg' },
  { name: SocialType.Discord, svgIcon: 'discord', link: 'https://discord.gg/NZmNbBj' },
  { name: SocialType.Twitter, svgIcon: 'socials-x', link: 'https://twitter.com/csgofast_X' },
  { name: SocialType.Vkontakte, svgIcon: 'vk-bold', link: 'https://vk.com/csgofast_official' },
  { name: SocialType.Instagram, svgIcon: 'instagram-line', link: 'https://www.instagram.com/csgofastcom' },
  // { name: SocialType.Steam, svgIcon:'steam-bold', link: '' },
];
