import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

export const expansionAnimation = trigger('expansionAnimation', [
  transition(':enter', [
    query(
      ':self',
      [
        style({ height: 0, margin: 0, 'padding-top': 0, 'padding-bottom': 0, opacity: 0, 'z-index': 1, overflow: 'hidden' }),
        sequence([
          animate('300ms 150ms ease-in-out', style({ height: '*', margin: '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 })),
        ]),
      ],
      { optional: true },
    ),
  ]),
  transition(':leave', [
    query(
      ':self',
      [
        style({ height: '*', margin: '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 }),
        sequence([animate('300ms 150ms ease-in-out', style({ height: 0, margin: 0, 'padding-top': 0, 'padding-bottom': 0, opacity: 0 }))]),
      ],
      { optional: true },
    ),
  ]),
]);
