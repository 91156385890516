<div class="sih-confirm tw-flex tw-gap-6 tw-whitespace-pre-line">
  <div class="tw-flex tw-flex-col">
    <div class="tw-font-medium tw-text-xl tw-gray-100 tw-mb-4">{{ 'SIH.SECOND_MODAL.HEADER' | translate }}</div>
    <div
      class="tw-font-medium tw-text-sm tw-text-gray-300 tw-mb-6"
      [innerHTML]="'SIH.SECOND_MODAL.BODY' | translate"
    ></div>
    <div class="tw-flex tw-gap-3 tw-pb-6">
      <button
        class="btn btn-primary--design btn-fill tw-py-3 tw-px-6 !tw-rounded-xl"
        [disabled]="loading()"
        (click)="onRequestPermissions()"
      >
        {{ 'SIH.SECOND_MODAL.BUTTON1' | translate }}
      </button>
      <button
        class="btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-min-w-32"
        [disabled]="isSihNotChecked() || loading()"
        [class.btn-basic--design]="isSihNotChecked() || loading()"
        (click)="onCheckSihInstall()"
      >
        @if (loading()) {
          <div class="tw-flex tw-items-center tw-justify-center">
            <mat-icon
              class="loading !tw-w-4 !tw-h-4"
              svgIcon="refresh-line"
            />
          </div>
        } @else {
          {{ 'SIH.SECOND_MODAL.BUTTON2' | translate }}
        }
      </button>
    </div>
    <!-- <app-ui-checkbox
      formControlName="autoMode"
      [innerLabels]="'Use auto mode as the main one' | translate"
    ></app-ui-checkbox> -->
  </div>
  <img
    [class.tw-opacity-40]="isSihNotChecked()"
    src="/assets/img/p2p/sih-confirm-example.svg"
    alt=""
  />
</div>
