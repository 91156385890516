@if (modes()?.length) {
  @if (isWeeklyEvent()) {
    <ui-game-card
      class="tw-mb-3"
      [cardNavigateUrl]="eventRouterLink"
      [cardImgSrc]="eventLogoSrc"
      [isActive]="activeUrl === '/events'"
      [matTooltip]="eventNameLocale"
      matTooltipPosition="right"
    >
    </ui-game-card>
    <div class="tw-w-6 tw-h-[2px] tw-bg-gray-600 tw-mb-3"></div>
  }
  @for (mode of modes(); track $index) {
    <div [class]="'menu__group menu__group_' + mode">
      @if (getMode(mode); as category) {
        @for (game of category; track $index) {
          <ui-game-card
            [cardNavigateUrl]="'/game/' + game.key"
            [cardImgSrc]="getGameIconSrc(game)"
            [cardHoverTitle]="game.title"
            [isActive]="activeUrl === '/game/' + game.key"
            [matTooltip]="game.title"
            matTooltipPosition="right"
          >
            <!-- Внутрь можно передать кастомный тег -->
            <!-- @if (newGames[game.key]) {
            <app-ui-tag></app-ui-tag>
          } -->
          </ui-game-card>
        }
      }
    </div>
  }
} @else {
  <app-ui-spinner-loader></app-ui-spinner-loader>
}
