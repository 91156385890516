import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExternalScriptsService {
  appendMobileCheckScript = () => {
    const s = document.createElement('script');
    s.type = 'module';
    s.src = './noindex/redirect-to-mobile.js';
    document.body.appendChild(s);
  };
}
