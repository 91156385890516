import { makeStateKey, TransferState } from '@angular/core';
import { LocalizationApiService } from '@dev-fast/backend-services';
import { TranslateLoader } from '@ngx-translate/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import LocalizationMock from 'libs/shared/assets/src/i18n/en_US.json';
import { catchError, Observable, of } from 'rxjs';

class CustomTransLoader implements TranslateLoader {
  private DEFAULT_LOCALE_KEY = makeStateKey('default-locale');

  constructor(
    private readonly localizationApiService: LocalizationApiService,
    private readonly transferState: TransferState,
  ) {}

  getTranslation(lang: string): Observable<object> {
    const defaultLocale = this.transferState.get(this.DEFAULT_LOCALE_KEY, LocalizationMock as any);
    // default-locale - язык переданные с пререндера. Ставится первым по-умолчанию пока не загрузятся запросы
    return (lang === 'default-locale' ? of(defaultLocale) : this.localizationApiService.getTranslation(lang)).pipe(
      catchError(() => this.localizationApiService.getTranslationFromAssets(lang)),
    );
  }
}

export function customTranslateLoader(localizationApiService: LocalizationApiService, transferState: TransferState): any {
  return new CustomTransLoader(localizationApiService, transferState);
}
