import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IS_SERVER_TOKEN } from '@app/shared/utils';

interface RequestDetails {
  url: string;
  requestTime: Date;
  responseTime: Date | undefined;
}
// Для отлова зависшых запросов при рендере статики
@Injectable()
export class DebuggingInterceptor implements HttpInterceptor {
  private requests: RequestDetails[] = [];
  constructor(
    protected readonly ngZone: NgZone,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
    private readonly router: Router,
  ) {
    if (this.isServer) {
      this.printTasksWithDelay();
    }
  }
  printTasksWithDelay(): void {
    const waitSeconds = 3000;

    // eslint-disable-next-line no-console
    console.log(`⏳ Wait ${waitSeconds}ms to dump pending tasks `);
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.printTasks();
      }, waitSeconds);
    });
  }
  printTasks(): void {
    // eslint-disable-next-line no-console
    console.log(`👀 ${this.router.url}`);
    // console.log(`👀 ${this.router.url}:`, this.requests);
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isServer) {
      return next.handle(request);
    }
    const requestDetails: {
      url: string;
      requestTime: Date;
      responseTime: Date | undefined;
    } = {
      url: request.url,
      requestTime: new Date(),
      responseTime: undefined,
    };
    this.requests.push(requestDetails);
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            requestDetails.responseTime = new Date();
          }
        },
        (error) => {
          requestDetails.responseTime = new Date();
        },
      ),
    );
  }
}
