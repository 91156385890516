<div class="tw-w-full tw-rounded-xl tw-bg-gray-650 tw-flex tw-items-center tw-justify-around tw-p-5">
  <div class="tw-flex tw-flex-col tw-items-center">
    <div class="buyer-info trade-result tw-flex tw-items-center tw-gap-3 tw-mb-3">
      <app-ui-user-avatar [url]="userAvatarHash() || '' | replaceAvaHash"></app-ui-user-avatar>
      <div class="tw-font-normal tw-text-xs tw-text-gray-100">{{ username() }}</div>
    </div>
    <div class="tw-font-medium text-xxs tw-text-gray-300 tw-mb-1">
      {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.GIVE' | translate: { count: tradeOffer()?.items?.length || 0 } }}
    </div>
    <app-currency
      class="tw-font-bold tw-text-lg tw-text-gray-100 tw-mb-4"
      [value]="tradeOffer()?.sellerPrice"
    />
    <div class="tw-flex tw-gap-1 tw-max-w-44 tw-flex-wrap">
      @for (item of getItemsOfferArray(tradeOffer()?.items); track $index) {
        <div
          class="tw-flex te-items-center tw-justify-cneter tw-w-10 tw-h-10 tw-rounded-md tw-bg-gray-700"
          [ngClass]="{ '!tw-bg-gray-500 tw-cursor-pointer hover:tw-bg-gray-400': !!item }"
        >
          @if (item && item?.baseItem?.icon) {
            <img
              errorHandler
              class="tw-w-9"
              [src]="item?.baseItem?.icon | hash"
              alt=""
            />
          }
        </div>
      }
    </div>
  </div>
  <mat-icon
    class="tw-text-gray-500 md:!tw-w-12 md:!tw-h-12"
    svgIcon="arrow-right-line"
  ></mat-icon>
  <div class="tw-flex tw-flex-col tw-items-center">
    <div class="buyer-info trade-result tw-flex tw-items-center tw-gap-3 tw-mb-3">
      <app-ui-user-avatar [url]="buyerAvatarHash | replaceAvaHash"></app-ui-user-avatar>
      <div class="tw-font-normal tw-text-xs tw-text-gray-100">{{ buyerSteamName }}</div>
    </div>
    <div class="tw-font-medium text-xxs tw-text-gray-300 tw-mb-1">
      {{ 'P2P_WIDGETS.SIH_TRADE.STEAM_GUARD.PROPOSE' | translate: { count: 0 } }}
    </div>
    <app-currency
      class="tw-font-bold tw-text-lg tw-text-gray-100 tw-mb-4"
      [value]="0"
    />
    <div class="tw-flex tw-gap-1 tw-max-w-44 tw-flex-wrap">
      @for (item of getItemsOfferArray(); track $index) {
        <div
          class="tw-flex te-items-center tw-justify-cneter tw-w-10 tw-h-10 tw-rounded-md tw-bg-gray-700"
          [ngClass]="{ '!tw-bg-gray-500 tw-cursor-pointer hover:tw-bg-gray-400': !!item }"
        >
          @if (item && item?.baseItem?.icon) {
            <img
              errorHandler
              class="tw-w-9"
              [src]="item?.baseItem?.icon | hash"
              alt=""
            />
          }
        </div>
      }
    </div>
  </div>
</div>
