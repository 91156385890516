<div
  class="notification-wrapper"
  (mouseover)="pauseNotifications()"
  (mouseleave)="resumeNotifications()"
>
  @for (notification of notifications(); track notification.id) {
    <app-notification-item
      @notificationSlideLeftAnimation
      [notification]="notification"
      [isLastNotify]="$last"
      [isPaused]="isPaused()"
      (resumeNotifications)="resumeNotifications()"
    />
  }
</div>
