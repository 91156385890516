<!-- <ng-container *ngIf="message">
<app-ui-user-avatar
  *ngIf="message.user"
  [url]="message.user.avatar"
  [userId]="message.user.id"
></app-ui-user-avatar>
<app-ui-user-avatar *ngIf="!message.user"></app-ui-user-avatar>

<div class="message-box">
  <div class="message-name">
    <div
      class="message-game-type icomoon"
      *ngIf="message.game !== 'common'"
      [ngClass]="getGameModeAndType(message.game)"
    ></div>
    <div
      class="message-username"
      *ngIf="message.user"
      [ngClass]="message.user.profileType"
      >
      {{ message.user.name }}
    </div>
    <div class="message-date">{{ message.createdAt | date: 'hh:mm' }}</div>
  </div>

  <div
    class="message-text"
    *ngIf="checkMessageType(message.value)"
    >
    {{ message.value }}
  </div>

  <div
    class="message-sticker"
    *ngIf="!checkMessageType(message.value)"
    >
    <img
      lazyLoadImage
      [src]="getSticker(message.value)"
      alt=""
      />
  </div>
</div>
</ng-container> -->

@if (message) {
  <div class="chat-message">
    <div class="chat-message__avatar">
      @if (message.user; as user) {
        <app-ui-user-avatar
          [url]="user.avatar"
          [type]="user.profileType"
          [userId]="user.id"
        ></app-ui-user-avatar>
      }
    </div>
    <div class="chat-message__username">{{ message.user.name }}:</div>
    @if (checkMessageType(message.value)) {
      <div class="chat-message__text">
        {{ message.value }}
      </div>
    }
    @if (!checkMessageType(message.value)) {
      <div class="chat-message__sticker">
        <img
          lazyLoadImage
          [src]="getSticker(message.value)"
          alt=""
        />
      </div>
    }
    <div class="chat-message__time">{{ message.createdAt | date: 'HH:mm:ss' }}</div>
  </div>
}
