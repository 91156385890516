import { inject, Injectable } from '@angular/core';
import { BreakpointsTypes, IP2pDepositingItem } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import { LayoutState } from '@app/core/state/layout';
import { P2pDepositState } from '@app/core/state/p2p';
import { CommonStoreService } from '@app/core-state-common';

@Injectable()
export class TradeCompleteService {
  #commonService = inject(CommonStoreService);

  @Select(LayoutState.breakpoints)
  readonly breakpoints$!: Observable<BreakpointsTypes | null>;

  @Select(P2pDepositState.depositingItems)
  readonly depositing$!: Observable<IP2pDepositingItem[]>;

  readonly user$ = this.#commonService.user$;

  readonly isMobile$: Observable<boolean> = this.breakpoints$.pipe(
    map((breakpoint) => {
      if (breakpoint && breakpoint.native) {
        return ['xxs', 'xs', 's'].includes(breakpoint.native);
      }
      return false;
    }),
  );
}
