@if (message) {
  <div
    class="chat-item"
    [class.chat-item--sended]="isMyMessage"
  >
    @if (!isMyMessage) {
      <div class="chat-item--header">
        @if (message.user) {
          <app-ui-user-avatar
            [url]="message.user.avatar"
            [userId]="message.user.id"
          ></app-ui-user-avatar>
        }
        <div>{{ message.user.name }}</div>
      </div>
    }
    <div class="chat-item--body">
      @if (checkMessageType(message.value)) {
        {{ message.value }}
      } @else {
        <div class="chat-message__sticker">
          <img
            lazyLoadImage
            [src]="getSticker(message.value)"
            [alt]="'chat message'"
          />
        </div>
      }
      <ng-template #sticker>
        <div class="chat-message__sticker">
          <img
            lazyLoadImage
            [src]="getSticker(message.value)"
            [alt]="'chat message'"
          />
        </div>
      </ng-template>
    </div>
    <div class="chat-item--time">{{ getDate(message.createdAt) }}</div>
  </div>
}
