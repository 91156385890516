import { Injectable } from '@angular/core';
import { IGame, IInputMessage, IMessage, IStickerPack, IUserDetailed } from '@dev-fast/types';
import { Select, Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { BehaviorSubject, Observable } from 'rxjs';

import { ChatState, CheckCanChat, GetMessagesList, ScrollToBottom, SendMessage, SetActiveStickerPack } from '@app/core/state/chat';
import { GamesState } from '@app/core/state/games-store';
import { ChangeActivePanel } from '@app/core/state/layout';
import { UserState } from '@app/core/state/user-store';
import { ChatEngine } from '@app/widgets/chat';

@Injectable()
export class ChatService implements ChatEngine {
  @Select(ChatState.items)
  messages$!: Observable<IMessage[]>;
  @Select(ChatState.stickers)
  stickers$!: Observable<IStickerPack[]>;
  @Select(ChatState.activeStickerPack)
  activeStickerPack$!: Observable<IStickerPack>;
  @Select(ChatState.chatId)
  chatId$!: Observable<string>;
  @Select(ChatState.isAnimationPlayed)
  isAnimationPlayed$!: Observable<boolean>;
  @Select(UserState.user)
  user$!: Observable<IUserDetailed | null>;
  @Select(GamesState.availableGames)
  availableGames$!: Observable<IGame[]>;
  @Select(GamesState.currentGame)
  currentGame$!: Observable<IGame | null>;

  private readonly banTimer: BehaviorSubject<string>;
  readonly banTimer$: Observable<string>;

  constructor(private readonly store: Store) {
    this.banTimer = new BehaviorSubject<string>('00:00:00');
    this.banTimer$ = this.banTimer.asObservable();
  }

  @Dispatch() addMessage = (msg: IInputMessage): SendMessage => new SendMessage(msg);
  @Dispatch() setActiveStickerPack = (name: string): SetActiveStickerPack => new SetActiveStickerPack(name);
  @Dispatch() getMessagesList = (): GetMessagesList => new GetMessagesList();
  @Dispatch() checkCanChat = (): CheckCanChat => new CheckCanChat();
  @Dispatch() scrollToBottom = (): ScrollToBottom => new ScrollToBottom();
  @Dispatch() closeWidget = (): ChangeActivePanel => new ChangeActivePanel(null);
}
