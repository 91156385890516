import { ChangeDetectionStrategy, Component, computed, inject, input, OnInit, output, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { provideAnimations } from '@angular/platform-browser/animations';
import { IFormattedMarketplaceItem, IP2pDepositingItemV2, OrderStatusEnum } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, filter, interval, map, startWith, switchMap, takeUntil, timer } from 'rxjs';

import { CurrencyComponent } from '@app/core/currency';
import { LocalStorageService } from '@app/core/local-storage-service';
import { expansionAnimation } from '@app/games/lib/shared/animations';
import { SihService } from '@app/widgets/sih';

import { TradeModalActionsComponent } from './components/modal-actions/trade-modal-actions.component';
import { SteamInventoryPositionComponent } from './components/steam-inventory-position/steam-inventory-position.component';
import { TradeInfoPanelComponent } from './components/trade-info-panel/trade-info-panel.component';
import { TradeItemsListComponent } from './components/trade-items-list/trade-items-list.component';
import { PAGES_DEFAULT } from './constants';

@Component({
  standalone: true,
  selector: 'app-p2p-manual-trade',
  templateUrl: './p2p-manual-trade.component.html',
  styleUrls: ['./p2p-manual-trade.component.scss', '../../../../styles/modal.scss'],
  animations: [expansionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SihService, provideAnimations()],
  imports: [
    TranslateModule,
    MatIconModule,
    CurrencyComponent,
    TradeInfoPanelComponent,
    TradeModalActionsComponent,
    TradeItemsListComponent,
    SteamInventoryPositionComponent,
  ],
})
export class P2pManualTradeComponent implements OnInit {
  depositingItem = input.required<IP2pDepositingItemV2>();
  isMobileView = input<boolean>();

  openAutoTradeTab = output();
  closeModal = output();

  // Template
  visiblePagesArray: number[] = PAGES_DEFAULT;

  allowedPagesDict: { [page: number]: IFormattedMarketplaceItem[] } = {};
  currentSteamItem = signal<IFormattedMarketplaceItem | null>(null);
  isPanelOpen = signal<'info' | 'inventory' | null>(null);

  offerTimer = toSignal(
    combineLatest([toObservable(this.depositingItem)]).pipe(
      map(([depositingItem]: [IP2pDepositingItemV2]) => depositingItem),
      filter((lot: IP2pDepositingItemV2) => Date.parse(lot.nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    ),
  );

  lot: Signal<IP2pDepositingItemV2 | undefined> = computed(() => {
    const depositItem = this.depositingItem();
    if (!depositItem || (depositItem.status !== OrderStatusEnum.WAIT_FOR_TRADE && !depositItem.items)) {
      this.closeModal.emit();
      return;
    }

    const formattedItems = this.#formatItemSteamPosition(depositItem.items);
    this.allowedPagesDict = this.#mapAllowedPages(formattedItems);

    return { ...depositItem, items: formattedItems };
  });

  // TODO временный блок на пару дней. Удалить потом
  readonly #storageService = inject(LocalStorageService);
  readonly warningSeen: WritableSignal<boolean | undefined> = signal(false);
  ////
  constructor() {
    // TODO временный блок на пару дней. Удалить потом
    this.warningSeen.set(this.#storageService.get('auctionCompletedWarningViewed'));
    /////
  }

  ngOnInit(): void {
    const [firstItem] = this.depositingItem().items;
    //todo переделать форматер в человеческий вид
    this.setActiveSteamItem(this.#formatItemSteamPosition([firstItem])[0]);
  }

  // TODO временный блок на пару дней. Удалить потом
  onCloseWarning(): void {
    this.warningSeen.set(true);
    this.#storageService.set('auctionCompletedWarningViewed', true);
  }

  highlightCurrentPage(page: number, steamPositionPage: number): boolean {
    return page === steamPositionPage;
  }

  setActiveSteamItem(newItem: IFormattedMarketplaceItem): void {
    this.currentSteamItem.set(newItem);
  }

  setActiveItemById(id: number): void {
    const lot = this.lot();
    if (!lot) {
      return;
    }
    const newItem = lot.items.find((item) => item.id === id);
    if (newItem) {
      this.currentSteamItem.set(newItem);
    }
  }

  setActiveFirstItemOnPage(page: IFormattedMarketplaceItem[]): void {
    const newItem = page.find((item) => !!item);

    if (newItem) {
      this.currentSteamItem.set(newItem);
    }
  }

  #mapAllowedPages(items: IFormattedMarketplaceItem[]): { [page: number]: IFormattedMarketplaceItem[] } {
    const dict: { [page: number]: IFormattedMarketplaceItem[] } = {};
    const availablePages: number[] = [];
    items.forEach((item) => {
      if (!dict[item.page]) {
        dict[item.page] = Array(16);
        availablePages.push(item.page);
      }
      dict[item.page].splice(this.#calculateLotPosition(item.line, item.number), 1, item);
    });
    this.visiblePagesArray = availablePages;
    return dict;
  }
  /**
   *
   * @param steamItemPositionX
   * @param steamItemPositionY
   * @returns индекс итема в пустом массиве из 16 элементов, который заполняет грид 4х4
   */
  #calculateLotPosition(steamItemPositionX: number, steamItemPositionY: number): number {
    return steamItemPositionX * 4 - (4 - steamItemPositionY) - 1;
  }

  /**
   *
   * @param items Стандартные маркетовые объекты
   * @returns Тот же массив, но теперь отдельно указаны страница строчка и позиция в строчке каждого итема
   */
  #formatItemSteamPosition(items: IFormattedMarketplaceItem[]): IFormattedMarketplaceItem[] {
    return items.map((depositItem, index) => {
      const [page, line, number] = depositItem.steamItemPosition;
      return { ...depositItem, indicator: index + 1, page, line, number };
    });
  }
}
