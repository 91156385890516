@if (activeFaq) {
  <ng-scrollbar [visibility]="'hover'">
    <div class="quick-faq__items-list">
      @for (faqItem of activeFaq.faq_items; track faqItem; let index = $index) {
        <div class="quick-faq__item">
          <div class="quick-faq__item-title">
            {{ faqItem.item_title }}
            @if (showClose && index === 0) {
              <button
                class="btn btn-sm sq btn-round btn-transparent-dark--design"
                (click)="clickClose()"
              >
                <mat-icon [svgIcon]="'close'"></mat-icon>
              </button>
            }
          </div>
          <div
            class="quick-faq__item-content"
            [innerHTML]="faqItem.item_text"
          ></div>
        </div>
      }
    </div>
  </ng-scrollbar>
}
