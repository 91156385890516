@switch (getTemplateType) {
  @case ('level') {
    <div class="chat-info-container">
      <span>{{ 'CHAT_WIDGET.RESTRICTION' | translate }}</span>
    </div>
  }
  @case ('banned') {
    <div class="ban-panel">
      <div class="ban-panel__icon">
        <mat-icon svgIcon="telegram"></mat-icon>
      </div>
      <div class="ban-panel__content">
        @if (chatAccess && chatAccess.banReason) {
          <span class="ban-panel__content-reason">
            {{ chatAccess.banReason | translate }}
          </span>
        }
        @if (chatAccess && chatAccess.bannedUntil) {
          <span class="ban-panel__content-until">{{ 'CHAT_WIDGET.BANNED' | translate }} {{ banTimer }}</span>
        }
      </div>
    </div>
  }
  @case ('access') {
    <div class="h-100 input-container">
      <form
        class="message message__block"
        [formGroup]="messageForm"
      >
        <div class="message__wrapper">
          <div class="textarea__wrapper">
            <tui-textarea
              formControlName="message"
              [expandable]="true"
              [rows]="5"
            >
            </tui-textarea>
            @if (canShowError) {
              <div
                class="message__error-wrapper"
                [class.error-color]="message && message.errors?.['maxlength']"
              >
                <span>{{ message.value?.length + '/ 139' }}</span>
              </div>
            }
          </div>
          <div class="message__buttons">
            <button
              class="chat-footer__btn chat-footer__btn_send"
              (click)="onAddMessage()"
              [disabled]="!messageForm.valid"
            >
              <mat-icon
                svgIcon="send-renew"
                class="chat-footer__btn_icon"
              ></mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  }
}
