@if (notification(); as notification) {
  <div
    class="notification-item"
    [class.success]="notification.type === NotificationType.Success"
    [class.info]="notification.type === NotificationType.Info"
    [class.error]="notification.type === NotificationType.Error"
  >
    <div class="notification-item__icon">
      <mat-icon [svgIcon]="getIcon(notification.type)"></mat-icon>
    </div>
    @if (notification.message || notification.category) {
      <div class="notification-item__content">
        @if (notification.category) {
          <div class="notification-item__header">
            {{ notification.category | titlecase }}
          </div>
        }
        <div class="notification-item__message">
          @if (notification.message) {
            @if (notification.legacy) {
              {{ notification.message }}
            } @else {
              {{ notification.message | appCurrencyTranslate: notification.params }}
            }
          }
        </div>
        @if (notification?.actions?.length) {
          <div class="tw-flex">
            @for (action of notification.actions; track action) {
              <div
                class="notification-item__action"
                (click)="action?.callback ? action.callback() : null"
              >
                {{ action.name }}
              </div>
            }
          </div>
        }
      </div>
    }
    <div
      class="notification-item__close"
      (click)="deleteNotification(notification)"
    >
      <button class="notification-item__close-btn">
        <mat-icon svgIcon="close-soft"></mat-icon>
      </button>
    </div>
    <div
      class="loading-row tw-absolute tw-bottom-0 tw-left-0 tw-w-0 tw-transition-all tw-duration-200 tw-ease-linear tw-h-0.5"
      [class]="notification.type"
      [style.width.%]="width()"
    ></div>
  </div>
}
