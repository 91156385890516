import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterLink } from '@angular/router';

import { LangRouterModule } from '@app/shared/directives';

@Component({
  selector: 'ui-game-card',
  standalone: true,
  imports: [LangRouterModule, RouterLink],
  templateUrl: './game-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameCardComponent {
  readonly titleType = input<'hover' | 'bottom'>('hover');
  readonly isActive = input(false);

  readonly cardNavigateUrl = input<string>();
  readonly cardImgSrc = input<string>();
  readonly cardHoverTitle = input<string>();
}
