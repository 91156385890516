import { Injectable } from '@angular/core';
import { ActivePanel, IP2pDepositingItem, IP2pDepositItem, IP2pPurchaseItem, ModalNames, NewPanel, Panel } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { combineLatest, map, Observable } from 'rxjs';

import { ChangeActivePanel, CloseAllPanels, ClosePanel, LayoutState, OpenAvailablePanel, OpenPanel } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { P2pBuyingState, P2pDepositState } from '@app/core/state/p2p';

@Injectable()
export class DesktopRightsidebarService {
  @Select(LayoutState.lockedPanels)
  lockedPanels$!: Observable<Panel[]>;
  @Select(LayoutState.activePanelNew)
  panels$!: Observable<NewPanel[]>;
  @Select(P2pDepositState.selected)
  private selected$!: Observable<IP2pDepositItem[]>;
  @Select(P2pBuyingState.purchasing)
  private purchasing$!: Observable<IP2pPurchaseItem[]>;
  @Select(P2pDepositState.depositingItems)
  private depositing$!: Observable<IP2pDepositingItem[]>;

  readonly isSomeItemsSelected$: Observable<boolean> = combineLatest([this.selected$, this.purchasing$, this.depositing$]).pipe(
    map(([selected = [], purchasing = [], depositing = []]) => !!(selected.length || purchasing.length || depositing.length)),
  );

  @Dispatch() changeActivePanel = (panel: ActivePanel): ChangeActivePanel => new ChangeActivePanel(panel);
  @Dispatch() openPanel = (panel: NewPanel): OpenPanel => new OpenPanel(panel);
  @Dispatch() closePanel = (panel: NewPanel): ClosePanel => new ClosePanel(panel);
  @Dispatch() closeAllPanels = (): CloseAllPanels => new CloseAllPanels();
  @Dispatch() openAvailablePanel = (): OpenAvailablePanel => new OpenAvailablePanel();

  @Dispatch() openSupportSelectorModal = (): OpenModal => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
}
