import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IP2pDepositingItemV2 } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { ReplaceAvatarHashPipeModule } from '@app/shared/pipes';
import { UserAvatarComponent } from '@app/ui/components/index';

@Component({
  standalone: true,
  selector: 'app-trade-info-panel',
  templateUrl: './trade-info-panel.component.html',
  styleUrls: ['./trade-info-panel.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, TranslateModule, MatIconModule, UserAvatarComponent, ReplaceAvatarHashPipeModule],
})
export class TradeInfoPanelComponent {
  lot = input<IP2pDepositingItemV2>();
}
