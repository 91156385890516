<a
  *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
  class="btn btn-outline-primary btn-bonuses"
  (click)="onOpenGleamModal()"
>
  <img
    src="/assets/img/header/summer-cat.jpg"
    class="btn-bonuses-image"
    alt="summer cuteness"
  />
  <span class="btn-bonuses-image--alt-text">Free</span>

  <div class="btn-bonuses-middle"></div>
</a>

<div class="promo-container">
  <button
    class="promo-btn"
    [disabled]="panelOpened"
    (click)="togglePromoPanel()"
  >
    <mat-icon svgIcon="gift"></mat-icon>
  </button>
  @if (panelOpened) {
    <div
      class="promo-content"
      (clickOutside)="closePanel()"
    >
      <app-promocode-mobile (closeEvent)="togglePromoPanel()"></app-promocode-mobile>
    </div>
  }
</div>
