import { ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { P2pBuyingService } from '@core/p2p-services/p2p-buying.service';
import { P2PModalService } from '@core/p2p-services/p2p-modal.service';
import { AgreementName, IBasedForm, IMarketplaceKitData, IUICheckboxLabel, ModalNames, PROJECT, QuickFaqName } from '@dev-fast/types';
import { combineLatest, Observable, Subject, take } from 'rxjs';

import { EnvironmentService } from '@app/core/environment-service';
import { AgreementsService } from '@app/core/state/agreements';
import { QuickFaqService } from '@app/core/state/faq';

import { expansionAnimation } from '../shared/animations/expansion.animation';

@Component({
  selector: 'app-p2p-purchase',
  templateUrl: './p2p-purchase.component.html',
  styleUrls: ['./p2p-purchase.component.scss', '../styles/modal.scss'],
  animations: [expansionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pPurchaseComponent implements OnInit {
  readonly #destroyRef = inject(DestroyRef);
  readonly #agreementsService = inject(AgreementsService);
  readonly #p2pBuyingService = inject(P2pBuyingService);
  readonly #p2pModalService = inject(P2PModalService);
  readonly #quickFaqService = inject(QuickFaqService);
  readonly #environmentService = inject(EnvironmentService);

  readonly #isProjectMarket = this.#environmentService.isProject({ name: PROJECT.MARKET });

  readonly checkValidation$: Subject<void> = new Subject();
  readonly purchasing$: Observable<IMarketplaceKitData[]> = this.#p2pBuyingService.purchasing$;
  readonly showWarn$: Observable<boolean> = this.#p2pBuyingService.showWarn$;
  readonly rwtAgree$: Observable<boolean> = this.#agreementsService.agreementByName$(AgreementName.RWT);
  readonly rwtForm: FormGroup<IBasedForm> = new FormGroup({
    agreement: new FormControl(false, {
      validators: Validators.requiredTrue,
      nonNullable: true,
    }),
  });

  readonly agreementLabels: IUICheckboxLabel[] = [
    {
      title: 'MARKET.RWT.AGREEMENT_P1',
    },
    {
      title: 'MARKET.RWT.AGREEMENT_LINK',
      href: this.#isProjectMarket ? '/faq/rwt' : '/tos',
      target: '_blank',
    },
    {
      title: 'MARKET.RWT.AGREEMENT_P2',
    },
  ];

  ngOnInit(): void {
    this.#subscribeEmitters();
  }

  #subscribeEmitters(): void {
    this.#agreementsService
      .agreementByName$(AgreementName.RWT)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((isAgree) => {
        this.rwtForm.controls.agreement.patchValue(isAgree, { emitEvent: false });
      });
    this.rwtForm.controls.agreement.valueChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe((isAgree) => {
      this.#agreementsService.setAgreement(AgreementName.RWT, isAgree);
    });
    combineLatest([
      this.#p2pModalService.isSomeModalOpened$,
      this.#quickFaqService.quickFaqStatus$(QuickFaqName.RWT),
      this.#agreementsService.agreementByName$(AgreementName.RWT),
    ])
      .pipe(take(1), takeUntilDestroyed(this.#destroyRef))
      .subscribe(([someModalOpened, wasClosed, isRWTAgree]) => {
        if (wasClosed || isRWTAgree) {
          return;
        }
        if (!someModalOpened && this.#isProjectMarket) {
          this.#p2pModalService.openModal(ModalNames.P2P_RWT, true);
        }
      });
  }

  onChangeNotifiedStatus(value: boolean): void {
    this.#p2pBuyingService.updateShowWarnValue(value);
  }

  trackingFunction(index: number, item: IMarketplaceKitData): number {
    return item.id;
  }
  goToStoreTab(): void {
    this.#p2pBuyingService.goToStoreMarketTab();
  }
  goToTabAndClosePanel(): void {
    this.#p2pBuyingService.closeMarketPanel();
  }
  agreeRWT(): void {
    this.#agreementsService.setAgreement(AgreementName.RWT, true);
  }
  checkValidation(): void {
    this.rwtForm.controls.agreement.markAsDirty();
    this.checkValidation$.next();
  }
}
