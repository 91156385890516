import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { IGame, IMessage, IStickerMessage } from '@dev-fast/types';

import { chatItemAnimation } from '../chat.animation';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [chatItemAnimation],
})
export class ChatItemComponent {
  @HostBinding('class.my') public my: boolean | undefined;
  @HostBinding('@chatItemAnimation') private animation = true;

  @Input() public message: IMessage | undefined;
  @Input() public availableGames: IGame[];
  @Input() public set userId(val: number | null | undefined) {
    this.my = val !== null && val !== undefined && this.message && this.message.user?.id === val;
    // this.cdr.detectChanges();
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    this.availableGames = [];
  }

  public checkMessageType(message: string | IStickerMessage): boolean {
    return typeof message === 'string';
  }

  public getGameModeAndType(key: string = ''): string {
    const { mode } = this.availableGames.find((game: IGame) => game.key === key.toLowerCase()) || { mode: 'common' };
    return `${mode} icon-${key.toLowerCase()}-mini`;
  }

  public getSticker(value: string | IStickerMessage): string {
    if (typeof value !== 'string') {
      return `${value.path}/pack/${value.id}.png`;
    }
    return '';
  }
}
