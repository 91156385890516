import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogDirective } from './confirm-dialog.directive';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmDialogDirective],
  imports: [CommonModule, MatIconModule, MatDialogModule, MatButtonModule, TranslateModule],
  exports: [ConfirmDialogDirective],
})
export class ConfirmDialogModule {}
