<button
  class="btn btn-md sq btn-round btn-transparent-dark--design notifications--btn"
  [class.tw-bg-gray-700]="isMarket()"
  [disabled]="showNotificationsPanel"
  (click)="openMenu()"
  [@notificationAnimation]="countNewNotifications()"
>
  <div class="notifications--icon-bell">
    <ng-content select="[icon]"></ng-content>
    @if (countNewNotifications()) {
      <div class="notifications--icon-status"></div>
    }
  </div>
</button>
@if (showNotificationsPanel) {
  <div
    class="notifications--list p-2 flex fx-column fx-justify-between"
    inViewport
    [inViewportOptions]="{ threshold: [0] }"
    (inViewportAction)="closeMenu($event)"
    (clickOutside)="onOutside()"
    @replacementScaleAnimation
  >
    <div class="flex fx-align-center fx-justify-between pb-2">
      <div class="header-text tw-font-bold">{{ 'NOTIFICATIONS.HEADER' | translate | titlecase }}</div>
      <button
        class="btn btn-sm sq btn-round btn-transparent-dark--design close-btn"
        (click)="closeMenu()"
      >
        <mat-icon
          class="header-icon"
          svgIcon="close"
        ></mat-icon>
      </button>
    </div>
    @if (notificationList(); as notifications) {
      @if (notifications.length) {
        <ng-scrollbar>
          <div class="flex fx-column gap-2 w-100">
            @for (notification of notifications; track trackingFunction($index, notification)) {
              <div
                class="notification-item flex"
                [class.new-noty]="notification.status === NotificationStatusEnum.new"
                (mouseenter)="onNotificationItemHover(notification)"
              >
                <div
                  class="notification-item--status flex fx-align-center fx-justify-center gap-1 w-100"
                  [ngClass]="notification.type"
                >
                  @switch (notification.type) {
                    @case (NotificationTypeEnum.Success) {
                      <mat-icon svgIcon="notification-success-outline"></mat-icon>
                    }
                    @case (NotificationTypeEnum.Error) {
                      <mat-icon svgIcon="notification-new-error"></mat-icon>
                    }
                    @case (NotificationTypeEnum.Info) {
                      <mat-icon svgIcon="notification-attention"></mat-icon>
                    }
                    @default {
                      <mat-icon svgIcon="notification-attention"></mat-icon>
                    }
                  }
                </div>
                <div class="notification-item--content flex fx-column fx-justify-center w-100">
                  <div class="notification flex fx-column fx-justify-center">
                    @if (notification.category) {
                      <div class="tw-font-medium">
                        {{ notification.category | titlecase }}
                      </div>
                    }
                    @if (notification.message) {
                      <div class="notification--body tw-font-normal">
                        @if (notification.legacy) {
                          {{ notification.message }}
                        } @else {
                          {{ notification.message | appCurrencyTranslate: notification.params }}
                        }
                      </div>
                    }
                  </div>
                  <div class="notification-item--content-timer tw-font-light">{{ getDate(notification.createDate) }}</div>
                </div>
              </div>
            }
          </div>
        </ng-scrollbar>
        <button
          class="btn btn-sm btn-semi-round btn-transparent-dark--design tw-font-normal mt-2"
          (click)="watchAll()"
        >
          {{ 'INVENTORY_PANEL.CLEAR_ALL' | translate }}
        </button>
      } @else {
        <app-ui-not-found-stub
          image="/assets/img/not-found-ufo.png"
          message="NOTIFICATIONS.EMPTY"
        ></app-ui-not-found-stub>
      }
    }
  </div>
}
