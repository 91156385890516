@if (
  {
    user: user$ | async,
    locales: locales$ | async,
    currentLocale: currentLocale$ | async,
    inventoryShortInfo: inventoryShortInfo$ | async,
    canLevelUp: canLevelUp$ | async,
    menu: menu$ | async,
    balance: userBalance$ | myAsync
  };
  as data
) {
  <div
    [ngClass]="{ 'header-wrapper_open-main-menu': isMainMenuOpen(data.menu) }"
    class="header-wrapper tw-flex tw-justify-between tw-items-center tw-gap-2 tw-w-full tw-p-3"
  >
    <app-ui-brand [link]="'/'"></app-ui-brand>
    @if (data.user) {
      <div class="header__actions">
        <ui-balance-block
          [inventoryCount]="data.inventoryShortInfo?.inventoryCount"
          [userBalance]="data.balance"
          (onopenTransactions)="onOpenTransactions($event)"
          (openInventory)="onOpenInventory()"
        >
        </ui-balance-block>
        <div class="delimiter"></div>
        <ui-promo-block (openGleamModal)="onOpenGleamModal()"></ui-promo-block>
      </div>
    }
    <div class="header-wrapper__menu">
      @if (hasWeeklyEvent()) {
        <app-daily-quest-event-menu />
      }
      @if (data.user) {
        <app-notifications-v2>
          <mat-icon
            icon
            svgIcon="bell-bold-design"
          ></mat-icon>
        </app-notifications-v2>
      } @else {
        <button
          class="login-btn btn btn-basic--design"
          (click)="onLogin()"
        >
          {{ 'LOGIN' | translate }}
        </button>
      }
      <app-header-action-menu
        [user]="data.user"
        [locales]="data.locales"
        [locale]="data.currentLocale"
        [canLevelUp]="data.canLevelUp"
        [userBalance]="data.balance"
      >
      </app-header-action-menu>
    </div>
  </div>
}
