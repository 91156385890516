import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

import { ConfigConfirmDialog } from './config';
import { ConfirmDialogService } from './confirm-dialog.service';

@Directive({
  selector: '[appConfirmDialog]',
})
export class ConfirmDialogDirective {
  @Input() public config: ConfigConfirmDialog | undefined;
  @Output() public confirmed: EventEmitter<boolean> = new EventEmitter();

  @HostListener('click', ['$event.target'])
  public openDialog(): void {
    if (!this.config) return;
    this.confirmDialogService.open(this.config, (res) => this.confirmed.emit(res));
  }
  constructor(private readonly confirmDialogService: ConfirmDialogService) {}
}
