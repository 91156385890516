import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ControlStateEnum, MenuItem } from '@dev-fast/types';

import { GameCardComponent } from '../game-card/game-card.component';

@Component({
  selector: 'app-right-controls',
  standalone: true,
  imports: [MatIconModule, GameCardComponent],
  templateUrl: './right-controls.component.html',
  styleUrls: ['./right-controls.component.scss'],
})
export class RightControlsComponent {
  @Input() items: MenuItem[] = [];
  controlStateEnum = ControlStateEnum;

  notyFormat(notys: number): string {
    return notys > 9 ? '9+' : `${notys}`;
  }
}
