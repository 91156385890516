<div class="balance__container">
  <ng-container *moduleIsOn="'inventory'">
    <a
      langRouterLink
      [routerLink]="'/inventory'"
      class="balance__container__skins"
    >
      <mat-icon
        class="menu-icon"
        svgIcon="automate-gun"
      />
      <span>{{ inventoryCount }}</span>
    </a>
  </ng-container>

  <div class="balance-panel">
    <app-currency
      class="balance-panel__coins"
      [value]="userBalance"
    />
    <button
      (click)="openTransactions(transactionWayEnum.REFILL)"
      class="refill-btn btn btn-inversion-green--design"
    >
      <mat-icon
        class="refill-btn__icon"
        svgIcon="plus-line-bold"
      ></mat-icon>
      <span class="refill-btn__label">{{ 'BALANCE_REFILL.LINK.REFILL' | translate | titlecase }}</span>
      <span class="refill-bonus">
        {{ bonusValue }}
      </span>
    </button>
    <button
      *isProject="{ name: projects.MARKET, exclude: true }"
      class="withdrawal-btn btn btn-outline-blue--design"
      (click)="openTransactions(transactionWayEnum.WITHDRAWAL)"
    >
      <mat-icon
        svgIcon="minus--design"
        class="withdrawal-btn__icon"
      ></mat-icon>
    </button>
  </div>
</div>
