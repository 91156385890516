import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IP2pDepositSettings } from '@dev-fast/types';

@Component({
  selector: 'app-p2p-sell-additional-settings',
  templateUrl: './p2p-sell-additional-settings.component.html',
  styleUrls: ['./p2p-sell-additional-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSellAdditionalSettingsComponent {
  @Input() lotDepositSettings: IP2pDepositSettings | null = null;
  @Output() settingsChanged: EventEmitter<IP2pDepositSettings> = new EventEmitter<IP2pDepositSettings>();

  panelExpanded = false;

  updateSettings(value: number | boolean | undefined): void {
    if (!this.lotDepositSettings) {
      return;
    }
    if (value == undefined) {
      return;
    }
    if (typeof value === 'number') {
      this.settingsChanged.emit({ ...this.lotDepositSettings, tradesDuration: value });
    } else {
      this.settingsChanged.emit({ ...this.lotDepositSettings, autoApprove: value });
    }
  }
}
