import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CountdownComponentModule, P2pLotCardModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { AppCurrencyModule } from '@app/shared/pipes';

import { P2pPurchaseTradeModalComponent } from './p2p-purchase-trade-modal.component';

@NgModule({
  declarations: [P2pPurchaseTradeModalComponent],
  imports: [CommonModule, MatIconModule, TranslateModule, AppCurrencyModule, CountdownComponentModule, P2pLotCardModule],
})
export class P2pPurchaseTradeModalModule {}
