import type { Environments } from '@app/core/environment-service';
import { ENVIRONMENTS_DEFAULT_FAST } from '@app/core/environment-service';
import { getStageBackBoneUrl, getStageFastGateway, normalizeHost } from '@app/shared/utils';
import { getLocation } from '@app/shared/utils/location';

const location = getLocation('csgofast.com');
export const environment: Environments = {
  ...ENVIRONMENTS_DEFAULT_FAST,
  production: false,
  IS_NEW_WALLETS: false,
  hmr: true,
  PROJECT: 'csgofast-new',
  CLIENT_PROJECT: 'renew-fast',
  BUILD_NAME: 'stage-client',
  HOSTNAME: `${location.host}`,
  // ---------- URLS
  WS_GATEWAY_URL: getStageFastGateway(location.hostname),
  GATEWAY_URL: getStageFastGateway(location.hostname),
  BACKBONE_URL: getStageBackBoneUrl(location.host),
  ESPORTS_API_URL: 'stage',
  PAYMENT_URL: `https://pay.${normalizeHost(location.hostname)}`,
  PAYMENT_URL_2: `https://pay.${normalizeHost(location.hostname)}`,
  WITHDRAWAL_URL: getStageFastGateway(location.hostname),
  POGGI_URL: 'https://d1phhoo4f618zo.cloudfront.net',
  // ---------- MODULES
  MODULES: {
    ...ENVIRONMENTS_DEFAULT_FAST.MODULES,
    transaction: {
      refill: [],
      withdrawal: [],
    },
    account: {
      main: [],
      balance: [],
      referrals: [],
    },
    soundToggler: true,
    inventory: {},
    games: [
      'classic',
      'case-battle',
      'fast',
      'double',
      'poggi',
      'baccarat',
      'hilo',
      'x50',
      'crash',
      'slot',
      'tower',
      'fortune-wheel',
      'cases',
      // 'match-bets',
      // 'craft',
    ],
  },
};
