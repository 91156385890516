import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

import { DebuggingInterceptor } from './lib/debug.ssr.interceptor.service';
import { NotificationsInterceptor } from './lib/notifications.interceptor.service';

export const httpInterceptorProviders = (
  isProd = false
): { useClass: any; provide: InjectionToken<HttpInterceptor[]>; multi: boolean }[] => {
  const list = [
    { provide: HTTP_INTERCEPTORS, useClass: NotificationsInterceptor, multi: true, isDemo: false },
    { provide: HTTP_INTERCEPTORS, useClass: DebuggingInterceptor, multi: true, isDemo: false },
  ];
  return list
    .filter((val) => (isProd ? val.isDemo !== isProd : true))
    .map((el) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { isDemo, ...payload } = el;
      return payload;
    });
};
