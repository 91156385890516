@if (items.length) {
  <div class="menu__group">
    @for (item of items; track item) {
      @if (item.status !== controlStateEnum.HIDEN) {
        <div
          class="menu__item"
          [class]="item.itemClass"
        >
          <ng-container>
            @if (item.icon) {
              <button
                (click)="item.callback && item.callback()"
                class="tw-relative"
              >
                @if (!!item.notificationCount && item.notificationCount()) {
                  <div class="icon-status tw-absolute tw-font-black tw-flex tw-items-center tw-justify-center tw-roun">
                    {{ notyFormat(item.notificationCount() || 0) }}
                  </div>
                }
                <mat-icon [svgIcon]="item.icon"> </mat-icon>
              </button>
            }
          </ng-container>
        </div>
      }
    }
  </div>
}
