import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterNewModule, WidgetWrapperComponent } from '@dev-fast/ui-components';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { CoreIframeModule } from '@app/core/iframe';
import { PromocodeService } from '@app/core/promocode-service';
import { DesktopAppHeaderNewModule } from '@app/desktop-app/header-new';
import { DesktopAppRightSidebarNewModule } from '@app/desktop-app/right-sidebar-new';
import { EventsCoreModule } from '@app/modules/events';
import { NotificationComponent } from '@app/notifications';
import { BottomControlPanelComponent, REFILL_BANNER_ENGINE, RefillBannerComponent } from '@app/shared/components';
import { ResizedModule } from '@app/shared/directives';
import { CustomAsyncPipeModule } from '@app/shared/pipes';
import { ChatModule } from '@app/widgets/chat';
import { WidgetsNotificationsNewModule } from '@app/widgets/notifications-new';
import { ParticipationPanelModule } from '@app/widgets/participation-panel';
import { PROMOCODE_ENGINE, PromocodeMobileComponent } from '@app/widgets/promocode';
import { RecentActivitiesWidgetsModule } from '@app/widgets/recent-activities';

import { DesktopGameMenuComponent } from './components/desktop-game-menu/desktop-game-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { MobileGameMenuModule } from './components/mobile-game-menu/mobile-game-menu.component';
import { RightControlsComponent } from './components/right-controls/right-controls.component';
import { GofastLayoutComponent } from './gofast-layout.component';
import { ChatService } from './services/chat.service';
import { MainModalsService } from './services/main-modals.service';
import { NotificationsService } from './services/notifications.service';
import { ParticipationPanelService } from './services/participation-panel.service';
import { RefillBannerService } from './services/refill-banner.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreIframeModule,
    DesktopAppHeaderNewModule,
    DesktopAppRightSidebarNewModule,
    NgScrollbarModule,
    NotificationComponent,
    WidgetWrapperComponent,
    ChatModule.forRoot(ChatService),
    WidgetsNotificationsNewModule.forRoot(NotificationsService),
    PortalModule,
    RecentActivitiesWidgetsModule,
    ResizedModule,
    FooterNewModule,
    DesktopGameMenuComponent,
    RefillBannerComponent,
    MobileGameMenuModule,
    HeaderComponent,
    PromocodeMobileComponent,
    RightControlsComponent,
    ParticipationPanelModule.forRoot(ParticipationPanelService),
    CustomAsyncPipeModule,
    BottomControlPanelComponent,
    //NOTE-EVENT: delete or comment after event
    EventsCoreModule,
  ],
  providers: [
    MainModalsService,
    {
      provide: PROMOCODE_ENGINE,
      useClass: PromocodeService,
    },
    {
      provide: REFILL_BANNER_ENGINE,
      useClass: RefillBannerService,
    },
  ],
  declarations: [GofastLayoutComponent],
  exports: [GofastLayoutComponent],
})
export class GofastLayoutModule {}
