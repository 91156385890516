@if (
  {
    user: user$ | async,
    locales: locales$ | async,
    currentLocale: currentLocale$ | async,
    inventoryShortInfo: inventoryShortInfo$ | async,
    canLevelUp: canLevelUp$ | async,
    menu: menu$ | async,
    balance: userBalance$ | myAsync
  };
  as data
) {
  <div
    [ngClass]="{ 'header-wrapper_open-main-menu': isMainMenuOpen(data.menu) }"
    class="header-wrapper"
  >
    <div class="header-wrapper__logo">
      <app-ui-brand
        [link]="'/'"
        [query]="{ type: 'buy' }"
      ></app-ui-brand>
    </div>
    @if (data.user) {
      <div class="header__actions">
        <app-balance-component
          [inventoryCount]="data.inventoryShortInfo?.inventoryCount"
          [userBalance]="data.balance"
          (openInventory)="onInventoryOpen()"
          (onopenTransactions)="onOpenWithdrawal($event)"
        >
        </app-balance-component>
      </div>
    }
    <div class="header-wrapper__menu">
      @if (data.user !== undefined) {
        @if (data.user) {
          <app-notifications-v2>
            <mat-icon
              icon
              svgIcon="bell-bold-design"
            ></mat-icon>
          </app-notifications-v2>
        } @else {
          <button
            class="login-btn btn btn-basic--design"
            (click)="onLogin()"
          >
            {{ 'LOGIN' | translate }}
          </button>
        }
        <app-header-action-menu
          [user]="data.user"
          [locales]="data.locales"
          [locale]="data.currentLocale"
          [canLevelUp]="data.canLevelUp"
          [userBalance]="data.balance"
        >
        </app-header-action-menu>
      } @else {
        <app-ui-spinner-loader></app-ui-spinner-loader>
      }
    </div>
  </div>
}
