import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgxsModule } from '@ngxs/store';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { FaqState } from '@app/core/state/faq';

import { QuickFaqComponent } from './quick-faq/quick-faq.component';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([FaqState]), NgScrollbarModule, MatIconModule],
  declarations: [QuickFaqComponent],
  exports: [QuickFaqComponent],
})
export class QuickFaqModule {}
