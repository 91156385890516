import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IChatAccess, IGame, IInputMessage, NullableForm } from '@dev-fast/types';

export const MAX_MESSAGE_LENGTH = 139;
@Component({
  selector: 'app-message-input-v2',
  templateUrl: './message-input.component.html',
  styleUrls: ['./message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageInputV2Component {
  @HostListener('document:keydown', ['$event'])
  handleKeyboardKeypressEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onAddMessage();
    }
  }
  @Output() addMessage: EventEmitter<IInputMessage>;
  @Output() setActivePack: EventEmitter<string>;

  @Input() currentGame: IGame | undefined;
  @Input() activeStickerPack: string | undefined;
  @Input() chatAccess: IChatAccess | undefined;
  @Input() banTimer: string | undefined;

  messageForm: FormGroup<NullableForm<{ message: string }>>;

  get message(): FormControl<string | null> {
    return this.messageForm.controls.message;
  }
  get canShowError(): boolean {
    return this.message.value && this.message.errors && this.message.errors['maxlength'];
  }
  get getTemplateType(): string {
    if (
      this.chatAccess &&
      !this.chatAccess.hasAccess &&
      this.chatAccess.bannedUntil &&
      new Date(this.chatAccess.bannedUntil).getTime() > Date.now()
    ) {
      return 'banned';
    }
    return 'access';
  }
  constructor(private fb: FormBuilder) {
    this.messageForm = this.fb.group({
      message: ['', [Validators.minLength(2), Validators.required, Validators.maxLength(MAX_MESSAGE_LENGTH)]],
    });

    this.addMessage = new EventEmitter(false);
    this.setActivePack = new EventEmitter(false);
  }

  onAddMessage(): void {
    const { message } = this.messageForm.value;
    if (!message || !message.trim() || !this.messageForm.valid) {
      return;
    }

    const game = this.currentGame ? this.currentGame.key : 'common';
    this.addMessage.emit({ value: message, game });
    this.messageForm.patchValue({ message: '' });
  }
}
