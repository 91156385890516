import { Route, Routes } from '@angular/router';
import { MAIN_LANG, TransactionStatus } from '@dev-fast/types';

import { IsAuthGuard } from '@app/auth';
import { AutoGameActivateChildGuard, AutoGameActivateGuard, AutoGameDeactivateGuard } from '@app/core/guards';
import { LanguageService } from '@app/core/language-service';
import { GofastLayoutComponent } from '@app/gofast/layout-new';
import { NotFoundPageComponent } from '@app/not-found-page';

import { routes as appRoutes } from './app.routes';

const notFoundPage = {
  path: '**',
  component: NotFoundPageComponent,
};

export const routes: Routes = [
  {
    path: '',
    component: GofastLayoutComponent,
    children: [
      {
        path: '',
        children: appRoutes,
      },
      {
        path: 'transaction',
        outlet: 'modal',
        loadChildren: () => import('@app/modules/transaction').then((modules) => modules.TransactionModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, IsAuthGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'fail-payment',
        outlet: 'modal',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('@app/gofast/modals-notification-layout').then((modules) => modules.ModalsNotificationLayoutModule),
        data: { paymentStatus: TransactionStatus.ERRORED },
      },
      {
        path: 'success-payment',
        outlet: 'modal',
        canActivate: [IsAuthGuard],
        loadChildren: () => import('@app/gofast/modals-notification-layout').then((modules) => modules.ModalsNotificationLayoutModule),
        data: { paymentStatus: TransactionStatus.RECEIVED },
      },
      ...(Object.keys(LanguageService.getRouteLanguages())
        .filter((item) => item !== MAIN_LANG)
        .map((lang) => ({
          path: lang,
          children: [...appRoutes, notFoundPage],
        })) as Route[]),
      notFoundPage,
    ],
  },
];
