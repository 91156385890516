@if (currentSteamItem(); as currentSteamItem) {
  <div class="tw-flex tw-flex-col">
    <div class="tw-font-normal tw-text-sm tw-text-gray-100 tw-text-start tw-pb-1">
      {{ currentSteamItem.page }}
      {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_1' | translate }}
      {{ currentSteamItem.line }}
      {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_2' | translate }}
      {{ currentSteamItem.number }}
    </div>
    <div class="tw-font-normal tw-text-sm tw-text-gray-400 tw-pb-6">
      {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_FOOTER' | translate }}
    </div>
    <div class="steam-inventory tw-grid tw-grid-cols-4 tw-grid-rows-4 tw-gap-3 tw-w-max tw-pb-4">
      @for (item of currentSteamInventoryPage(); track $index) {
        @if (item) {
          <div
            class="tw-w-20 tw-h-20 md:tw-w-16 md:tw-h-16 tw-rounded-lg tw-text-blue-500 tw-border tw-border-blue-500 tw-flex tw-items-center tw-justify-center tw-relative tw-cursor-pointer"
            [class.tw-bg-blue-800]="item.indicator === currentSteamItem.indicator"
            (click)="setItemActive.emit(item.id)"
          >
            <img
              errorHandler
              class="tw-w-20 tw-h-20 md:tw-w-16 md:tw-h-16"
              [src]="item.baseItem.icon | hash"
            />
            <span class="tw-absolute tw-text-sm tw-bottom-[0%] tw-left-[7%]">{{ item.indicator }}</span>
          </div>
        } @else {
          <div
            class="tw-w-20 tw-h-20 md:tw-w-16 md:tw-h-16 tw-rounded-lg tw-bg-gray-700 md:tw-bg-gray-600 tw-flex tw-items-center tw-justify-center"
          >
            <mat-icon
              class="tw-text-gray-500 md:tw-text-gray-500 !tw-w-10 !tw-h-10 md:!tw-w-8 md:!tw-h-8"
              svgIcon="gun-simple-detailed"
            ></mat-icon>
          </div>
        }
      }
    </div>

    @if (pagesSorted(); as sortedPages) {
      @if (pagesToShow(); as pagesToShow) {
        <div class="tw-flex tw-gap-2 tw-items-center tw-justify-center">
          <mat-icon
            class="!tw-w-4 !tw-h-4 tw-text-gray-500"
            [ngClass]="{ '!tw-text-gray-300 tw-cursor-pointer': sortedPages[0] !== pagesToShow[0] }"
            svgIcon="arrow-left-compact-bold"
            (click)="setPageActive.emit(pagesToShow[0])"
          ></mat-icon>
          @for (page of pagesToShow; track $index) {
            <div
              class="tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 md:tw-w-8 md:tw-h-8 tw-rounded-lg tw-border tw-border-gray-400 tw-text-gray-400 tw-font-normal tw-text-sm hover:tw-bg-blue-500 hover:tw-text-gray-100 tw-cursor-pointer"
              [ngClass]="{
                'tw-bg-blue-500 tw-border-none !tw-text-gray-100 ': page === currentSteamItem.page,
                'tw-opacity-15 tw-pointer-events-none': !sortedPages.includes(page)
              }"
              (click)="setPageActive.emit(page)"
            >
              {{ page }}
            </div>
          }
          <mat-icon
            class="!tw-w-4 !tw-h-4 tw-text-gray-500"
            [ngClass]="{
              '!tw-text-gray-300 tw-cursor-pointer':
                sortedPages[sortedPages.length - 1] !== pagesToShow[pagesToShow.length - 1] && pagesToShow.length > 6
            }"
            svgIcon="arrow-right-compact-bold"
            (click)="setPageActive.emit(pagesToShow[pagesToShow.length - 1])"
          ></mat-icon>
        </div>
      }
    }
  </div>
}
