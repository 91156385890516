import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PROJECT, REFILL_REGULAR_BONUS, TransactionWay } from '@dev-fast/types';

@Component({
  selector: 'app-balance-component',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss', './balance.media.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceComponent {
  @Input() public userBalance!: number;
  @Input() public inventoryCount!: number | undefined;

  @Output() public openInventory: EventEmitter<void> = new EventEmitter<void>();
  @Output() public onopenTransactions: EventEmitter<TransactionWay> = new EventEmitter();

  public transactionWayEnum: typeof TransactionWay = TransactionWay;
  public projects: typeof PROJECT = PROJECT;
  public bonusValue = `+${REFILL_REGULAR_BONUS}%`;

  public setInventoryPanelActive: any = (): void => this.openInventory.emit();

  public openTransactions = (way: TransactionWay): void => this.onopenTransactions.emit(way);
}
