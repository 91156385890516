import { DOCUMENT } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { fromEvent } from 'rxjs';

import { CookieService } from '@app/core/cookie-service';
import { ExternalScriptsService } from '@app/core/external-scripts-service';
import { SeoService } from '@app/core/seo-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { environment } from '../environments/environment';

@Component({
  selector: 'app-new-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly #externalScriptsService = inject(ExternalScriptsService);
  readonly #cookieService = inject(CookieService);
  readonly #isServer = inject(IS_SERVER_TOKEN);
  readonly #seoService = inject(SeoService);
  readonly #document = inject(DOCUMENT);

  constructor() {
    this.#seoService.start();
    if (this.#isServer) {
      return;
    }
    this.#document.fonts.load('1em EventFonts');
    this.#handleNonServerTasks();
  }

  ngOnInit(): void {
    if (!this.#isServer) {
      // this.coreLicenseService.appendLicenseScript();
      this.#externalScriptsService.appendMobileCheckScript();
    }
  }
  #handleNonServerTasks(): void {
    this.#setupResizeWatcher();
    this.#redirectIfHttp();
    this.#setCookie();
  }
  #redirectIfHttp(): void {
    if (window.location.protocol === 'http:' && environment.production) {
      window.location.href = location.href.replace('http', 'https');
    }
  }

  #setCookie(): void {
    this.#cookieService.setCookie('angularHost', window.location.host, {
      path: '/',
      expires: 'Tue, 19 Jan 2038 03:14:07 GMT',
    });
  }
  #setupResizeWatcher(): void {
    this.#updatePosition();
    fromEvent(window, 'resize').subscribe(() => this.#updatePosition());
  }

  #updatePosition(): void {
    const vh = window.innerHeight * 0.01;
    if (!this.#isServer) {
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }
}
