import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IFaq } from '@dev-fast/types';

@Component({
  selector: 'app-quick-faq',
  templateUrl: './quick-faq.component.html',
  styleUrls: ['./quick-faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickFaqComponent {
  @Input() public activeFaq: IFaq | undefined;
  @Input() public showClose = true;

  @Output() public oncloseClick: EventEmitter<void> = new EventEmitter();

  public clickClose(): void {
    this.oncloseClick.emit();
  }
}
