import { IInputMessage, IMessage } from '@dev-fast/types';

// export class CloseWidgetChat {
//   static readonly type = '[Chat] Close Widget Chat';
// }
export class SendMessage {
  static readonly type = '[Chat] Send Message';
  constructor(readonly payload: IInputMessage) {}
}
export class GetMessagesList {
  static readonly type = '[Chat] Get Messages List';
}
export class CheckCanChat {
  static readonly type = '[Chat] Check Can Chat';
}
export class DeleteMsgEventHandling {
  static readonly type = '[Chat] Delete Msg Event Handling';
  constructor(readonly payload: IMessage) {}
}
export class CreateMsgEventHandling {
  static readonly type = '[Chat] Create Msg Event Handling';
  constructor(readonly payload: IMessage) {}
}
export class SetActiveStickerPack {
  static readonly type = '[Chat] Set Active Sticker Pack';
  constructor(readonly name: string) {}
}
export class SetChatID {
  static readonly type = '[Chat] Set Chat ID';
  constructor(readonly chatId: string) {}
}
export class ScrollToBottom {
  static readonly type = '[Chat] ScrollToBottom';
}
