import { Injectable } from '@angular/core';
import { IUserP2pPermissionInterface, ModalNames, SocialType } from '@dev-fast/types';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { map, Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { OpenModal } from '@app/core/state/modals';
import { GetDepositModifier, GetDepositModifierSuccess, P2pCommonState, SetDepositModifier } from '@app/core/state/p2p';
import { UserState } from '@app/core/state/user-store';

@Injectable()
export class P2pSellService {
  @Select(UserState.p2pPermissions)
  readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;
  @Select(P2pCommonState.sellerBanEndAt)
  readonly sellerBanEndAt$!: Observable<number | null>;

  depositModifier$ = this.actions$.pipe(
    ofActionSuccessful(GetDepositModifierSuccess),
    map((action: GetDepositModifierSuccess) => action.payload),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
  ) {}

  bindProvider(provider: SocialType): void {
    this.authService.bindProvider(provider);
  }
  @Dispatch() getDepositModifier = (): GetDepositModifier => new GetDepositModifier();
  @Dispatch() setDepositModifier = (payload: { agree: boolean }): SetDepositModifier => new SetDepositModifier(payload);

  @Dispatch() openSupportModal = (): OpenModal => new OpenModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  @Dispatch() openSteamSetupModal = (): OpenModal => new OpenModal(ModalNames.P2P_SETUP);
}
