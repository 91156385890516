import { OrderStatusEnum } from '@dev-fast/types';

export const ORDER_SUCCESS_STATUSES: OrderStatusEnum[] = [
  OrderStatusEnum.WAIT_FOR_TRADE,
  OrderStatusEnum.WAIT_FOR_CONFIRM,
  OrderStatusEnum.WAIT_FOR_BUYER_ACCEPT,
];
export const ORDER_CANCELED_STATUSES: OrderStatusEnum[] = [
  OrderStatusEnum.CANCELED_BY_BUYER,
  OrderStatusEnum.CANCELED_BY_SELLER,
  OrderStatusEnum.CANCELED_BY_FASTOFFER,
  OrderStatusEnum.CANCELED_BY_SELLER_BEFORE_TRADE,
];
export const BID_ON_ORDER_STATUSES: OrderStatusEnum[] = [
  OrderStatusEnum.FIRST_BID,
  OrderStatusEnum.SECOND_BID,
  OrderStatusEnum.THIRD_PLUS_BID,
];
export type PosibleBodyBorderClass = 'lot-success' | 'lot-completed' | 'lot-canceled' | '';
