import { ChangeDetectionStrategy, Component, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { SIH_LINK, SihService } from '@app/widgets/sih';

@Component({
  standalone: true,
  selector: 'app-sih-check-panel',
  templateUrl: './sih-check-panel.component.html',
  styleUrls: ['./sih-check-panel.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SihService],
  imports: [TranslateModule, MatIconModule],
})
export class SIHCheckPanelComponent {
  readonly #sihService = inject(SihService);

  readonly isMobileView = input<boolean>(false);
  readonly goBack = output();

  readonly loading = toSignal(this.#sihService.checkUserLoading$);
  readonly sihLink = SIH_LINK;

  onCheckSihInstall(): void {
    this.#sihService.checkUser();
  }
}
