<div class="modal-actions tw-grid tw-gap-3">
  @if (offerTimer(); as offerTimer) {
    <a
      rel="noopener noreferrer"
      class="accept-trade btn btn-primary--design btn-fill tw-p-3 tw-w-full !tw-no-underline !tw-rounded-xl tw-min-w-64"
      target="_blank"
      [href]="depositingItem()?.order?.buyerTradeLink"
      [style.--fill-percent]="getBtnFillPercent(offerTimer) + '%'"
      [class.disabled]="offerTimer <= minTimeLeftForTrade"
    >
      {{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.SEND_TRADE' | translate }}
      {{ offerTimer > minTimeLeftForTrade ? (offerTimer | date: 'hh:mm:ss') : '00:00' }}
    </a>
  }
  <button
    class="trade-info btn tw-px-6 tw-py-3 !tw-rounded-xl !tw-bg-red-500 !tw-text-gray-100"
    (click)="openTradeInfo.emit()"
  >
    {{ 'P2P_WIDGETS.SIH_TRADE.IMPORTANT' | translate }}
  </button>
  <button
    class="close-trade btn btn-basic--design tw-px-6 tw-py-3 !tw-rounded-xl"
    (click)="closeModal.emit()"
  >
    {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.CANCEL_BUTTON' | translate }}
  </button>
  <button
    class="auto-trade btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-w-full"
    (click)="openAutoTradeTab.emit()"
  >
    @if (isMobileView()) {
      {{ 'P2P_WIDGETS.SIH_TRADE.AUTOSEND' | translate }}
    } @else {
      {{ 'P2P_WIDGETS.SIH_TRADE.AUTOSEND_BUTTON' | translate }}
    }
  </button>
</div>
