import { ChangeDetectionStrategy, Component, inject, OnInit, output, viewChild } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { IInputMessage, IMessage, IUserDetailed } from '@dev-fast/types';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, tap } from 'rxjs';

import { CHAT_ENGINE } from '@app/widgets/chat';

@Component({
  selector: 'app-chat-v2',
  templateUrl: './chat.component.html',
  styleUrl: './chat.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatV2Component implements OnInit {
  #chatEngine = inject(CHAT_ENGINE);

  authAttempt = output<void>();

  scrollbarRef = viewChild(NgScrollbar);

  #isAnimationPlayed = toSignal(this.#chatEngine.isAnimationPlayed$);

  user$: Observable<IUserDetailed | null> = this.#chatEngine.user$;
  messages$: Observable<IMessage[]> = this.#chatEngine.messages$.pipe(
    tap(() => {
      const isScrollAnimationTriggered = this.#isAnimationPlayed();

      if (isScrollAnimationTriggered) {
        this.scrollToBottom(0);
      } else {
        this.scrollToBottom(150);
        this.#chatEngine.scrollToBottom();
      }
    }),
  );

  ngOnInit(): void {
    this.#chatEngine.checkCanChat();
    this.#chatEngine.getMessagesList();
  }

  scrollToBottom(duration = 1): void {
    setTimeout(() => this.#scroll(duration), 0);
  }

  sendMsg(msg: IInputMessage): void {
    this.#chatEngine.addMessage(msg);
    this.scrollToBottom();
  }

  isMyMessage(message: IMessage, user: IUserDetailed | null): boolean {
    return user !== null && user !== undefined && message && message.user?.id === user.id;
  }

  #scroll(duration: number): void {
    const scrollRef = this.scrollbarRef();

    if (!scrollRef) {
      return;
    }

    scrollRef.scrollTo({ bottom: 0, duration });
  }
}
