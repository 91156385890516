@if (lot(); as lot) {
  <div class="tw-flex tw-items-end tw-justify-center tw-mb-6">
    <div
      class="tw-flex tw-flex-col tw-justify-between tw-h-36 tw-min-w-36 tw-py-4 tw-pl-3 tw-pr-5 tw-bg-gray-600 tw-rounded-s-xl tw-relative"
    >
      <div class="tw-flex tw-items-center tw-gap-2">
        <app-ui-user-avatar [url]="lot.order.buyerSteamAvatar | replaceAvaHash"></app-ui-user-avatar>
        <div class="tw-font-normal tw-text-sm tw-max-w-16 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
          {{ lot.order.buyerSteamName }}
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-3 tw-font-normal tw-text-xs tw-text-gray-400">
        <mat-icon
          svgIcon="green-arrow--design"
          class="green-arrow-position tw-absolute !tw-w-32 !tw-h-auto"
        ></mat-icon>
        <div>{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.LEVEL' | translate }}</div>
        <mat-icon
          svgIcon="white-arrow--design"
          class="white-arrow-position tw-absolute !tw-w-16 !tw-h-auto"
        ></mat-icon>
        <div>{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.REGISTRATION_DATE' | translate }}</div>
      </div>
    </div>
    <div class="tw-flex tw-flex-col tw-bg-gray-500 tw-rounded-t-xl tw-py-4 tw-px-3">
      <div class="tw-font-medium tw-text-xs tw-text-gray-100 tw-mb-3">Steam App</div>
      <div class="tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-gray-650 tw-rounded-lg tw-mb-2 tw-w-32 tw-h-11">
        <div class="tw-flex tw-items-center tw-justify-center tw-relative">
          <mat-icon
            class="tw-absolute"
            svgIcon="lvl-chevron--design"
          ></mat-icon>
          <div class="tw-z-10 tw-font-normal text-sups tw-text-gray-100">{{ lot.order.buyerSteamLevel }}</div>
        </div>
        <div class="tw-font-normal tw-text-xs tw-text-gray-400 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis tw-max-w-14">
          {{ lot.order.buyerSteamName }}
        </div>
      </div>
      <div class="tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-gray-650 tw-rounded-lg tw-w-32 tw-h-11">
        <app-ui-user-avatar [url]="lot.order.buyerSteamAvatar | replaceAvaHash"></app-ui-user-avatar>
        <div class="tw-font-normal tw-text-xs tw-text-gray-100">
          {{ lot.order.buyerSteamCreatedAt | date: 'd MMM, y' }}
        </div>
      </div>
    </div>
  </div>
  <div class="tw-flex tw-flex-col tw-gap-1 tw-mb-3">
    <div class="tw-font-medium tw-text-sm red-500">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.POSITION_ATTENTION_HEADER' | translate }}</div>
    <div class="tw-font-normal tw-text-xs tw-text-gray-300">{{ 'P2P_WIDGETS.SIH_TRADE.NOTICE' | translate }}</div>
  </div>
  <div class="tw-flex tw-flex-col tw-gap-1">
    <div class="tw-font-medium tw-text-sm red-500">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.ALERT_TITLE' | translate }}</div>
    <div class="tw-font-normal tw-text-xs tw-text-gray-300">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.ALERT_DESCRIBE' | translate }}</div>
  </div>
}
