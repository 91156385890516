import { Injectable } from '@angular/core';
import { IUserP2pPermissionInterface, SihParams, SocialType } from '@dev-fast/types';
import { Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '@app/auth';
import { GetP2pPermissions, UserState } from '@app/core/state/user-store';

@Injectable()
export class P2pPurchaseService {
  @Select(UserState.p2pPermissions)
  readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;
  sih$: Observable<SihParams | undefined> = this.p2pPermissions$.pipe(map((permissions) => permissions?.connections?.SIH));

  constructor(private readonly authService: AuthService) {}

  @Dispatch() checkUser = (): GetP2pPermissions => new GetP2pPermissions();
  bindProvider(provider: SocialType): void {
    this.authService.bindProvider(provider);
  }
}
