export class Timer {
  id: number;
  remaining: number;
  #_timerId: any; // должен быть числом какбудто но сборка ругается а тайпскрипт при этом норм
  #_start: number;
  readonly #_callback: () => void;

  constructor(id: number, isResume: boolean, callback: () => void, delay: number) {
    this.id = id;
    this.#_timerId = delay;
    this.#_start = delay;
    this.remaining = delay;
    this.#_callback = callback;
    if (isResume) {
      this.resume();
    }
  }

  remainingTime(): number {
    return this.remaining;
  }

  pause(): void {
    clearTimeout(this.#_timerId);
    this.remaining -= Date.now() - this.#_start;
  }

  resume(): void {
    this.#_start = Date.now();
    clearTimeout(this.#_timerId);
    this.#_timerId = setTimeout(this.#_callback, this.remaining);
  }
}
