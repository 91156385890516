import { Component, EventEmitter, Input, input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IP2pDepositItem } from '@dev-fast/types';

import { IColorPalette, SKIN_RARITY_V2 } from '@app/shared/utils';

export const FAST_SELL_OVERPRICE = -3;
export const SLOW_SELL_OVERPRICE = 10;
export const VERY_SLOW_SELL_OVERPRICE = 30;

@Component({
  selector: 'app-p2p-sell-setup-item',
  templateUrl: './p2p-sell-setup-item.component.html',
  styleUrls: ['./p2p-sell-setup-item.component.scss'],
})
export class P2pSellSetupItemComponent implements OnChanges {
  @Input() duration: number | undefined;
  @Input() item: IP2pDepositItem | undefined;

  @Output() itemRemoved: EventEmitter<void> = new EventEmitter<void>();

  safeOverprice = input<number>();

  bgColor = 'none';
  hvColor = 'none';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['item']) {
      const color = this.#getColorPalette(changes['item'].currentValue.baseItem.rarity, changes['item'].currentValue.baseItem.color);
      this.bgColor = color.default ?? 'none';
      this.hvColor = color.hover ?? 'none';
    }
  }

  increaseFieldColorClass(
    overprice: number,
    safeOverprice = -4,
  ): 'var(--color-green-500)' | 'var(--color-red-500)' | 'var(--color-yellow-500)' | '#fa8200' {
    switch (true) {
      case overprice < FAST_SELL_OVERPRICE:
      case overprice <= safeOverprice:
        return 'var(--color-green-500)';
      case overprice > VERY_SLOW_SELL_OVERPRICE && overprice > safeOverprice:
        return 'var(--color-red-500)';
      case overprice > SLOW_SELL_OVERPRICE && overprice > safeOverprice:
        return '#fa8200';
      default:
        return 'var(--color-yellow-500)';
    }
  }
  removeAt(): void {
    this.itemRemoved.emit();
  }

  #getColorPalette(rarity: string, color: string): IColorPalette {
    return SKIN_RARITY_V2[(rarity ?? color).toLowerCase()];
  }
}
