@if (stickers) {
  @for (sticker of getActiveStickers(stickers); track sticker) {
    <button
      class="btn btn-dark btn-sm chat-sticker"
      (click)="onStickerClick(sticker, stickers)"
    >
      <img
        class="chat-sticker-image"
        lazyLoadImage
        [src]="getStickerPath(stickers, 'previews', sticker)"
        alt=""
      />
    </button>
  }
}
