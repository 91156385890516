import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { ILocales, NewMenu } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import find from 'lodash-es/find';

@Component({
  selector: 'app-language-panel',
  templateUrl: './language-panel.component.html',
  styleUrls: ['./language-panel.component.scss'],
  standalone: true,
  imports: [MatRadioModule, MatIconModule, TranslateModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePanelComponent {
  locale = input<ILocales | null>();
  locales = input<ILocales[] | null>();

  languageChange = output<ILocales>();
  closePanel = output<NewMenu>();

  menu = NewMenu;
  currentLanguage = computed(() => this.locale()?.path);

  onLangChange(path: string): void {
    const findLocale = find(this.locales(), { path });
    if (findLocale) {
      this.languageChange.emit(findLocale);
    }
  }

  onClosePanel(panel: NewMenu): void {
    this.closePanel.emit(panel);
  }
}
