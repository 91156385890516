import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PROJECT, REFILL_REGULAR_BONUS, TransactionWay } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { IsProjectModule, LangRouterModule, ModuleIsOnModule } from '@app/shared/directives';

@Component({
  selector: 'ui-balance-block',
  standalone: true,
  imports: [
    CommonModule,
    IsProjectModule,
    LangRouterModule,
    MatIconModule,
    ModuleIsOnModule,
    TranslateModule,
    CurrencyComponent,
    MatTooltipModule,
  ],
  templateUrl: './balance-block.component.html',
  styleUrls: ['./balance-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BalanceBlockComponent {
  @Input() userBalance = 0;
  @Input() inventoryCount: number | undefined;

  @Output() openInventory: EventEmitter<void> = new EventEmitter<void>();
  @Output() onopenTransactions: EventEmitter<TransactionWay> = new EventEmitter();

  transactionWayEnum: typeof TransactionWay = TransactionWay;
  projects: typeof PROJECT = PROJECT;
  bonusValue = `+${REFILL_REGULAR_BONUS}%`;

  openTransactions = (way: TransactionWay): void => {
    this.onopenTransactions.emit(way);
  };
  onOpenInventory = (): void => this.openInventory.emit();
}
