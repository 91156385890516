import { animate, group, query, style, transition, trigger } from '@angular/animations';

export const chatContainerAnimation = trigger('chatContainerAnimation', [
  transition('void => *', [
    group([
      query('app-message-input', [
        style({ transform: 'translateY(100%)' }),
        animate('300ms 500ms ease-in-out', style({ transform: 'translateY(0%)' })),
      ]),
    ]),
  ]),
  transition('* => void', [
    group([
      query(
        'app-chat-item',
        [style({ transform: 'translateX(0)' }), animate('300ms ease-out', style({ transform: 'translateX(150%)' }))],
        {
          optional: true,
        }
      ),
      query('app-message-input', [animate('300ms 100ms ease-in-out', style({ transform: 'translateY(100%)' }))]),
    ]),
  ]),
]);

export const chatItemAnimation = trigger('chatItemAnimation', [
  transition('void => *', [
    query(':self', [style({ opacity: 0 }), animate('150ms 50ms ease-out', style({ opacity: 1 }))]),
  ]),
]);
