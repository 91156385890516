import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[appChatScrollPosition]',
})
export class ChatDirective {
  @Input() public tags: string[];
  @Output() public section: EventEmitter<string>;
  public current: string | undefined;

  constructor(private elementRef: ElementRef) {
    this.tags = ['div'];
    this.section = new EventEmitter<string>();
  }

  @HostListener('scroll', ['$event'])
  private onScroll(event: any): void {
    let currentSection: string | undefined;
    const { children } = this.elementRef.nativeElement;
    const { scrollTop } = event.target;
    for (let i = 0; i < children.length; i++) {
      const element = children[i];
      if (this.tags.some((tag) => tag.toUpperCase() === element.tagName)) {
        if (scrollTop >= element.offsetTop && scrollTop <= element.offsetTop + element.clientHeight) {
          currentSection = element.dataset.id;
        }
      }
    }
    if (currentSection !== this.current) {
      this.current = currentSection;
      this.section.emit(currentSection);
    }
  }
}
