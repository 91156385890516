import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import type { ModuleWithProviders, TypeProvider } from '@angular/core';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
// import { ChatService } from './chat.service';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LazyLoadImageModule } from '@app/shared/directives';

import { ChatComponent } from './chat.component';
import { ChatDirective } from './chat.directive';
import { ChatItemComponent } from './chat-item/chat-item.component';
import { MessageInputComponent } from './message-input/message-input.component';
import { StickersComponent } from './stickers/stickers.component';
import { CHAT_ENGINE } from './symbols';

@NgModule({
  declarations: [ChatComponent, StickersComponent, MessageInputComponent, ChatItemComponent, ChatDirective],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    UserAvatarModule,
    ScrollingModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatIconModule,
    NgScrollbarModule,
    LazyLoadImageModule,
    NgxsFormPluginModule,
    TranslateModule.forChild(),
  ],
  exports: [ChatComponent],
  // bootstrap: [ChatComponent],
})
export class ChatModule {
  static forRoot(engine: TypeProvider): ModuleWithProviders<ChatModule> {
    return {
      ngModule: ChatModule,
      providers: [
        {
          provide: CHAT_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
