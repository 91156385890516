import { InjectionToken } from '@angular/core';
import { INotification, PROJECT } from '@dev-fast/types';
import { Observable } from 'rxjs';

export const NOTIFICATIONS_ENGINE = new InjectionToken('NOTIFICATIONS_ENGINE');

export interface NotificationsEngine {
  readonly project: PROJECT;
  readonly notifications$: Observable<INotification[]>;
  readonly countNewNotifications$: Observable<number>;

  removeNotification(id: number): void;
  watchNotification(id: number): void;
  watchAllNotifications(): void;
  closeWidget(): void;
  openWidget(): void;
}
