import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { NotificationType } from '@dev-fast/types';
// import * as Sentry from '@sentry/angular-ivy';
import { Observable, tap } from 'rxjs';

import { NotificationsService } from '@app/core/notification-service';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

@Injectable()
export class NotificationsInterceptor implements HttpInterceptor {
  constructor(
    private readonly notificationsService: NotificationsService,
    @Inject(IS_SERVER_TOKEN) private isServer: boolean,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event) => {
          // eslint-disable-next-line no-console
          // if (event instanceof HttpResponse) console.log('Server response');
        },
        error: (err) => {
          if (err instanceof HttpErrorResponse) {
            // eslint-disable-next-line no-console
            if (err.status == 403) {
              // eslint-disable-next-line no-console
              console.log('Unauthorized');
            }
            // this.onError(err);
            return;
          }

          // NOTE: По просьбе бэка отправляем в сентри больше данных об ошибке если статускод 400
          // сделал массивом чтобы можно было дополнять его другими статус кодами
          if ([400].includes(err.status) && !this.isServer) {
            // Записываем информацию об ошибке в Sentry
            // Sentry.captureException({
            //   status: err.status,
            //   message: err.message,
            //   response: err.error, // <- Отправляем в Sentry то, что бэк отдал
            // });
          }
          // Возвращаем ошибку обратно в поток
          throw err;
        },
      }),
    );
  }
  #onError(error: HttpErrorResponse): void {
    this.notificationsService.addErrorNotification(
      error.error && error.error.message ? error.error.message : typeof error.error === 'string' ? error.error : 'Error',
      { type: error.error && error.error.type ? error.error.type : NotificationType.Error },
    );
  }
}
