import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { NotFoundStubModule, UserAvatarModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiTextareaModule } from '@taiga-ui/kit';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LazyLoadImageModule } from '@app/shared/directives';
import { AppCurrencyTranslateModule } from '@app/shared/pipes';
import { CHAT_ENGINE } from '@app/widgets/chat';

import { ChatV2Component } from './chat.component';
import { ChatItemComponent } from './chat-item/chat-item.component';
import { MessageInputV2Component } from './message-input/message-input.component';

@NgModule({
  declarations: [ChatV2Component, ChatItemComponent, MessageInputV2Component],

  imports: [
    CommonModule,
    MatIconModule,
    NotFoundStubModule,
    UserAvatarModule,
    LazyLoadImageModule,
    ReactiveFormsModule,

    NgScrollbarModule,
    TranslateModule,
    AppCurrencyTranslateModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
  ],
  exports: [ChatV2Component],
})
export class ChatV2Module {
  static forRoot(engine: TypeProvider): ModuleWithProviders<ChatV2Module> {
    return {
      ngModule: ChatV2Module,
      providers: [
        {
          provide: CHAT_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
