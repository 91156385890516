import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IP2pDepositingItem, IP2pDepositingItemV2 } from '@dev-fast/types';
import { combineLatest, interval, timer } from 'rxjs';
import { filter, map, startWith, switchMap, takeUntil } from 'rxjs/operators';

import { SihService } from '@app/widgets/sih';

import { TradeCompleteService } from './p2p-trade-complete.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-p2p-new-auction-completed-modal',
  templateUrl: './p2p-auction-completed-modal.component.html',
  styleUrl: './p2p-auction-completed-modal.component.scss',
  providers: [TradeCompleteService, SihService],
})
export class P2pNewAuctionCompletedModalComponent {
  readonly #dialogRef = inject(MatDialogRef<P2pNewAuctionCompletedModalComponent>);
  readonly #service = inject(TradeCompleteService);
  readonly #sihService = inject(SihService);

  readonly depositItemId: number = inject(MAT_DIALOG_DATA);

  readonly isMobileView = toSignal<boolean>(this.#service.isMobile$);
  readonly isFirstStep = toSignal<boolean>(this.#sihService.sih$.pipe(map((sih) => (sih ? !sih.online : true))));
  readonly isSihDisabled = toSignal<boolean>(this.#sihService.showSihWarn$);

  readonly depositingItem$ = this.#service.depositing$.pipe(
    filter((items) => items.some((el) => el.id === this.depositItemId)),
    map((items) => items.find((el) => el.id === this.depositItemId) as IP2pDepositingItemV2),
    filter((item): item is IP2pDepositingItemV2 => item !== undefined),
  );

  readonly depositingItem = toSignal(
    combineLatest([this.depositingItem$]).pipe(map(([depositingItem]) => depositingItem as IP2pDepositingItemV2)),
  );

  readonly offerTimer = toSignal(
    combineLatest([this.depositingItem$]).pipe(
      map(([depositingItem]) => depositingItem as IP2pDepositingItemV2),
      filter((lot: IP2pDepositingItem) => Date.parse(lot.nextStatusAt) > Date.now()),
      switchMap(({ nextStatusAt }) =>
        interval(1000).pipe(
          startWith(Date.parse(nextStatusAt) - Date.now()),
          map(() => Date.parse(nextStatusAt) - Date.now()),
          takeUntil(timer(Date.parse(nextStatusAt) - Date.now())),
        ),
      ),
    ),
  );

  activeTabIndex: 0 | 1 = 0;

  get SIHTradeInProgress(): boolean {
    return !!(this.activeTabIndex && this.depositingItem()?.order.senderAutomatedTrading && !this.isFirstStep() && !this.isSihDisabled());
  }

  constructor() {
    effect(() => {
      const item = this.depositingItem();
      if (!item) {
        this.activeTabIndex = 0;
        return;
      }
      this.activeTabIndex = item.order.senderAutomatedTrading ? 1 : 0;
    });
  }

  onClose(): void {
    this.#dialogRef.close();
  }
}
