import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, output, viewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { IFormattedMarketplaceItem } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbar, NgScrollbarModule } from 'ngx-scrollbar';

import { IColorPalette, SKIN_RARITY_V2 } from '@app/shared/utils';
import { P2pLotCardModule } from '@app/ui/components/index';

@Component({
  standalone: true,
  selector: 'app-trade-items-list',
  templateUrl: './trade-items-list.component.html',
  styleUrls: ['./trade-items-list.component.scss', '../../shared-styles.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [DatePipe, TranslateModule, MatIconModule, NgScrollbarModule, P2pLotCardModule],
})
export class TradeItemsListComponent {
  items = input<IFormattedMarketplaceItem[]>();
  currentItem = input<IFormattedMarketplaceItem | null>();
  direction = input<'horizontal' | 'vertical'>('vertical');
  isMobileView = input<boolean>(false);

  setItemActive = output<number>();
  openInventoryPanel = output();

  scrollbarRef = viewChild(NgScrollbar);
  itemsContainerRef = viewChild<ElementRef>('itemsContainer');

  itemColorPalette(item: IFormattedMarketplaceItem): IColorPalette {
    return SKIN_RARITY_V2[(item.baseItem.rarity ?? item.baseItem.color).toLowerCase()];
  }

  constructor() {
    effect(() => {
      const currentItem = this.currentItem();
      const items = this.items();
      const scrollbarRef = this.scrollbarRef();
      const itemsContainerRef = this.itemsContainerRef();
      if (!currentItem || !scrollbarRef || !items || !itemsContainerRef) {
        return;
      }
      const ItemRefInScroll = itemsContainerRef.nativeElement.children[currentItem.indicator - 1];
      if (ItemRefInScroll) {
        scrollbarRef.scrollToElement(ItemRefInScroll, { left: 0, top: -25, duration: 600 });
      }
    });
  }
}
