import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
// import { ChatService } from './chat.service';
import { IGame, IInputMessage, IMessage, IStickerPack, IUserDetailed } from '@dev-fast/types';
import { NgScrollbar } from 'ngx-scrollbar';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CHAT_ENGINE, ChatEngine } from './symbols';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  // animations: [widgetsMenuComponentAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatComponent implements OnInit, OnDestroy {
  @ViewChild(NgScrollbar) public scrollbarRef: NgScrollbar | undefined;
  @ViewChild('autosize') public inputContainer: ElementRef | undefined;
  // @HostBinding('@widgetsMenuComponentAnimation') private animation = true;

  private chatOffsetHeight: number;
  public scrollerVisible: boolean;
  public messages$: Observable<IMessage[]>;
  public availableGames$: Observable<IGame[]>;
  public user$: Observable<IUserDetailed | null>;
  public stickers$: Observable<IStickerPack[]>;
  private destroyed$: Subject<void>;

  constructor(@Inject(CHAT_ENGINE) private chatEngine: ChatEngine, private cdr: ChangeDetectorRef) {
    this.destroyed$ = new Subject<void>();

    this.messages$ = this.chatEngine.messages$.pipe(tap(() => this.scrollToBottom()));
    this.availableGames$ = this.chatEngine.availableGames$;
    this.user$ = this.chatEngine.user$;
    this.stickers$ = this.chatEngine.stickers$;

    this.chatOffsetHeight = 0;
    this.scrollerVisible = false;
  }
  public ngOnInit(): void {
    this.chatEngine.checkCanChat();
    this.chatEngine.getMessagesList();
  }
  public trackingFunction(index: number, item: any): number {
    return item.id;
  }
  public sendMsg(msg: IInputMessage): void {
    this.chatEngine.addMessage(msg);
    this.scrollToBottom();
  }
  public getUserId(user: IUserDetailed | null): null | number {
    return user ? user?.id : user;
  }
  public scrollToBottom(): void {
    setTimeout(() => this.scroll(), 0);
  }

  public scroll(): void {
    if (this.scrollbarRef) {
      // this.perfectScrollbar.directiveRef?.scrollToBottom();
      this.scrollbarRef.scrollTo({ bottom: 0 });
    }
  }

  public onScroll(event: any): void {
    const { scrollTop, scrollHeight, clientHeight } = event.target;
    const scrollerVisible = scrollTop + clientHeight < scrollHeight - clientHeight;
    if (scrollerVisible !== this.scrollerVisible) {
      this.scrollerVisible = scrollerVisible;
      this.cdr.detectChanges();
    }
  }

  public onClose(): void {
    this.chatEngine.closeWidget();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
