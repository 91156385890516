import { inject, Injectable } from '@angular/core';
import { ILocales, IUserDetailed } from '@dev-fast/types';
import { Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import { LanguageService } from '@app/core/language-service';
import { UserService } from '@app/core/user-service';
import { RefillBannerEngine } from '@app/shared/components';

@Injectable()
export class RefillBannerService implements RefillBannerEngine {
  readonly #userService: UserService = inject(UserService);
  readonly #authService: AuthService = inject(AuthService);
  readonly #languageService: LanguageService = inject(LanguageService);

  readonly currentLocale$: Observable<ILocales> = this.#languageService.currentLocale$;
  readonly user$: Observable<IUserDetailed | null | undefined> = this.#userService.user$;

  openAuthModal(): void {
    this.#authService.openLoginProvidersModal();
  }
}
