import { ChangeDetectionStrategy, Component, computed, inject, input, output } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatTooltipModule } from '@angular/material/tooltip';
import type { GameMode, GameStatus, IGame } from '@dev-fast/types';
import { EventStageEnum, GAMES_DETAILS, NEW_GAMES } from '@dev-fast/types';
import { SpinnerLoaderSAComponent } from '@dev-fast/ui-components';

import { LanguageService } from '@app/core/language-service';
import { StandardEventService } from '@app/modules/events';
import { DAILY_QUEST_EVENT_CONFIG } from '@app/shared/utils';

import { getModes } from '../../utils';
import { GameCardComponent } from '../game-card/game-card.component';

@Component({
  selector: 'ui-desktop-game-menu',
  standalone: true,
  imports: [GameCardComponent, SpinnerLoaderSAComponent, MatTooltipModule],
  templateUrl: './desktop-game-menu.component.html',
  styleUrls: ['./desktop-game-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopGameMenuComponent {
  readonly #languageService = inject(LanguageService);
  readonly #dailyEventService = inject(StandardEventService);

  readonly #eventConfig = inject(DAILY_QUEST_EVENT_CONFIG);

  readonly state = input<Record<string, GameStatus>>({});
  readonly availableGames = input<Partial<Record<GameMode, IGame[]>> | null | undefined>();
  readonly currentGame = input<IGame | undefined>();

  readonly selectionChange = output<IGame>();
  readonly toggleFullView = output<boolean>();

  readonly stage = toSignal(this.#dailyEventService.eventStage$);
  readonly isWeeklyEvent = computed(() => this.stage() !== EventStageEnum.ENDED);

  readonly modes = computed<GameMode[] | undefined>(() => {
    const availableGames = this.availableGames();
    if (!availableGames) {
      return;
    }

    return getModes(availableGames);
  });

  readonly eventRouterLink = this.#languageService.getCurrentLangUrl('/events');

  readonly eventLogoSrc = this.#eventConfig.eventLogoIconSrc;
  readonly eventNameLocale = this.#eventConfig.eventNameLocale;

  getGameIconSrc(game: IGame): string | undefined {
    return GAMES_DETAILS[game.key]?.icon;
  }

  activeUrl = '/';
  readonly newGames = NEW_GAMES;

  getMode(mode: GameMode): IGame[] | undefined {
    const availableGames = this.availableGames();
    if (!availableGames) {
      return;
    }

    return availableGames[mode];
  }
}
