<div class="p2p-panel flex fx-column">
  <div class="p2p-tab flex fx-column">
    <mat-tab-group
      dynamicHeight
      class="tabs-default tabsContainer"
      [(selectedIndex)]="activeTab"
    >
      <mat-tab>
        <ng-template mat-tab-label> {{ 'P2P_WIDGETS.BUY' | translate }} </ng-template>
        <div class="panel-wrapper">
          <app-p2p-purchase></app-p2p-purchase>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label> {{ 'P2P_WIDGETS.SELL' | translate }} </ng-template>
        <div class="panel-wrapper">
          <app-p2p-sell></app-p2p-sell>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
