import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { SpinnerLoaderSAComponent, TradesStubComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { QuickFaqModule } from '@app/modules/quick-faq';
import { ModuleIsOnModule } from '@app/shared/directives';
import { ChatV2Module } from '@app/widgets/chat-v2';
import { P2PWidgetNewModule } from '@app/widgets/p2p-new/p2p.module';

import { DesktopRightSidebarNewComponent } from './desktop-right-sidebar.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    ChatV2Module,
    P2PWidgetNewModule,
    // P2pWidgetNewModule.forRoot(DesktopRightsidebarService),
    ModuleIsOnModule,
    TradesStubComponent,
    QuickFaqModule,
    SpinnerLoaderSAComponent,
  ],
  declarations: [DesktopRightSidebarNewComponent],

  exports: [DesktopRightSidebarNewComponent],
})
export class DesktopAppRightSidebarNewModule {}
