import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { FloatIndicatorModule, P2pLotCardModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { ImageErrorHandlerDirective } from '@app/shared/directives/image-error-handler/image-error-handler.directive';
import { AppCurrencyModule, HashPipe, ReplacePipeModule, StickerHashPipe } from '@app/shared/pipes';

import { P2pSellSetupItemComponent } from './p2p-sell-setup-item.component';

@NgModule({
  declarations: [P2pSellSetupItemComponent],
  exports: [P2pSellSetupItemComponent],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    ReplacePipeModule,
    AppCurrencyModule,
    HashPipe,
    StickerHashPipe,
    MatSliderModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    FormsModule,
    P2pLotCardModule,
    FloatIndicatorModule,
    CurrencyComponent,
    ImageErrorHandlerDirective,
  ],
})
export class P2pSellSetupItemModule {}
