import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { P2pMarketService } from '@core/p2p-services/p2p-market.service';
import { CURRENCY_AMOUNT, MarketPanel, MAX_ACCEPTABLE_AUTODEPOSIT_PRICE } from '@dev-fast/types';
import { Observable, Subject } from 'rxjs';

import { CurrencyService } from '@app/core/currency';

@Component({
  selector: 'app-p2p-sell-adviser',
  templateUrl: './p2p-sell-adviser.component.html',
  styleUrls: ['./p2p-sell-adviser.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSellAdviserComponent {
  readonly #location = inject(Location);

  readonly currentPath = this.#location.path();

  @Input() selected = false;
  @Input() set selectedSum(value: number | null) {
    if (value !== null) {
      const currency: number = this.#currencyService.convert(value);
      this.adviserFormGroup.patchValue({ currency }, { emitEvent: false });
    }
  }

  @Output() goToSteamInventoryMarketTabMobile: EventEmitter<void> = new EventEmitter<void>();
  @Output() reqAutoSelect: EventEmitter<number> = new EventEmitter<number>();

  readonly #p2pMarketService = inject(P2pMarketService);
  readonly #currencyService = inject(CurrencyService);
  readonly #moneyToCoinsTransferRate = this.#currencyService.convertCurrencyToCurrency('fastcoins_refill', 'USD', 1);
  readonly activeTab$: Observable<MarketPanel> = this.#p2pMarketService.activeTab$;
  readonly checkValidation$: Subject<void> = new Subject();
  readonly MarketPanelEnum = MarketPanel;

  readonly adviserFormGroup = new FormGroup({
    currency: new FormControl<number | undefined>(undefined, {
      validators: [
        Validators.min(this.#moneyToCoinsTransferRate),
        Validators.max(MAX_ACCEPTABLE_AUTODEPOSIT_PRICE),
        Validators.pattern(CURRENCY_AMOUNT),
      ],
    }),
  });

  reqAutoSelectSteamItems(): void {
    if (this.adviserFormGroup.valid && this.adviserFormGroup.controls.currency.value) {
      this.reqAutoSelect.emit(this.adviserFormGroup.controls.currency.value);
    } else {
      this.adviserFormGroup.controls.currency.markAsDirty();
      this.checkValidation$.next();
    }
  }

  goToInventoryMarketTabMobile = (): void => {
    this.goToSteamInventoryMarketTabMobile.emit();
  };
}
