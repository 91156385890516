import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ui-any-icon-card',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './any-icon-card.component.html',
  styleUrls: ['./any-icon-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnyIconCardComponent {
  @Input() titleType: 'hover' | 'bottom' = 'hover';
  @Input() title: string | undefined;
  @Input() icon: string | undefined;
}
