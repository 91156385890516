import { IGiftProvider } from '@dev-fast/types';

export const GIFT_PROVIDERS: IGiftProvider[] = [
  {
    name: 'KINGUIN',
    items: [
      { amount: 500, link: 'https://www.kinguin.net/category/82757/csgofast-5-usd-gift-card' },
      { amount: 1000, link: 'https://www.kinguin.net/category/82747/csgofast-10-usd-gift-card' },
      { amount: 2500, link: 'https://www.kinguin.net/category/82758/csgofast-25-usd-gift-card' },
      { amount: 5000, link: 'https://www.kinguin.net/category/82759/csgofast-50-usd-gift-card' },
      { amount: 10000, link: 'https://www.kinguin.net/category/82760/csgofast-100-usd-gift-card' },
      { amount: 20000, link: 'https://www.kinguin.net/category/82761/csgofast-200-usd-gift-card' },
      { amount: 50000, link: 'https://www.kinguin.net/category/124316/csgofast-500-usd-gift-card' },
      { amount: 100000, link: 'https://www.kinguin.net/category/124317/csgofast-1000-usd-gift-card' },
    ],
  },
  // {
  //   name: 'G2A',
  //   items: [
  //     { amount: 500, link: 'https://www.g2a.com/csgofast-5-usd-csgofast-key-global-i10000280070006?aid=13099141' },
  //     { amount: 1000, link: 'https://www.g2a.com/csgofast-10-usd-csgofast-key-global-i10000280070005?aid=13099146' },
  //     { amount: 2500, link: 'https://www.g2a.com/csgofast-25-usd-csgofast-key-global-i10000280070003?aid=13099147' },
  //     { amount: 5000, link: 'https://www.g2a.com/csgofast-50-usd-csgofast-key-global-i10000280070004?aid=13099148' },
  //     { amount: 10000, link: 'https://www.g2a.com/csgofast-100-usd-csgofast-key-global-i10000280070002?aid=13099151' },
  //     { amount: 20000, link: 'https://www.g2a.com/csgofast-200-fast-coins-csgofast-key-global-i10000280070001' },
  //     { amount: 50000, link: 'https://www.g2a.com/csgofast-500-fast-coins-csgofast-key-global-i10000280070008' },
  //     { amount: 100000, link: 'https://www.g2a.com/csgofast-1000-fast-coins-csgofast-key-global-i10000280070007' },
  //   ],
  // },
];
