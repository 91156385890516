import { DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { P2pManualTradeComponent } from './components/p2p-manual-trade/p2p-manual-trade.component';
import { P2pSIHAutoTradeComponent } from './components/p2p-sih-auto-trade/p2p-sih-auto-trade.component';
import { P2pNewAuctionCompletedModalComponent } from './p2p-auction-completed-modal.component';

@NgModule({
  declarations: [P2pNewAuctionCompletedModalComponent],
  exports: [P2pNewAuctionCompletedModalComponent],
  imports: [DatePipe, TranslateModule, MatTabsModule, P2pManualTradeComponent, P2pSIHAutoTradeComponent, NgScrollbarModule],
})
export class P2pAuctionCompletedModalModule {}
