@if (isMobileView()) {
  <div class="tw-font-medium tw-text-xl tw-text-center tw-pb-6">
    {{ 'SIH.FIRST_MODAL.HEADER' | translate }}
  </div>
  <div class="tw-flex tw-justify-center tw-pb-6">
    <mat-icon
      class="!tw-w-48 !tw-h-auto tw-text-gray-400"
      svgIcon="sih-at-desktop"
    ></mat-icon>
  </div>
  <div class="tw-text-sm tw-font-normal tw-text-gray-300 tw-pb-4">
    {{ 'P2P_WIDGETS.SIH_TRADE.AUTO_SEND.BODY1' | translate }}
    <a
      [href]="sihLink"
      target="_blank"
      class="tw-text-blue-500 tw-underline"
    >
      {{ 'SIH.REFILL.SIH' | translate }}
    </a>
    {{ 'P2P_WIDGETS.SIH_TRADE.AUTO_SEND.BODY2' | translate }}
  </div>
  <div class="tw-font-normal tw-text-xs tw-text-gray-400 tw-pb-6">
    {{ 'P2P_WIDGETS.SIH_TRADE.AUTO_SEND.IF' | translate }}
  </div>
  <div class="tw-flex tw-gap-3">
    <button
      class="btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-w-full"
      [disabled]="loading()"
      [class.btn-basic--design]="loading()"
      (click)="onCheckSihInstall()"
    >
      @if (loading()) {
        <div class="tw-flex tw-items-center tw-justify-center">
          <mat-icon
            class="loading !tw-w-4 !tw-h-4"
            svgIcon="refresh-line"
          />
        </div>
      } @else {
        {{ 'SIH.SECOND_MODAL.BUTTON2' | translate }}
      }
    </button>
    <button
      class="close-trade btn btn-basic--design tw-px-6 tw-py-3 !tw-rounded-xl"
      (click)="goBack.emit()"
    >
      {{ 'RENEW_PAGES.INVENTORY.GO_BACK' | translate }}
    </button>
  </div>
} @else {
  <div class="tw-font-medium tw-text-xl tw-pb-4">
    {{ 'SIH.FIRST_MODAL.HEADER' | translate }}
  </div>
  <div class="tw-text-sm tw-font-normal tw-text-gray-300 tw-pb-6">
    {{ 'SIH.REFILL.BODY' | translate }}
    <a
      [href]="sihLink"
      target="_blank"
      class="tw-text-blue-500 tw-underline"
    >
      {{ 'SIH.REFILL.SIH' | translate }}
    </a>
    {{ 'SIH.REFILL.SECOND_BODY' | translate }}
  </div>
  <div class="tw-flex tw-gap-3 tw-pb-4">
    <a
      [href]="sihLink"
      target="_blank"
      class="btn btn-primary--design tw-p-3 !tw-no-underline !tw-rounded-xl"
    >
      {{ 'SIH.FIRST_MODAL.BUTTON' | translate }}
    </a>
    <button
      class="btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-min-w-32"
      [disabled]="loading()"
      [class.btn-basic--design]="loading()"
      (click)="onCheckSihInstall()"
    >
      @if (loading()) {
        <div class="tw-flex tw-items-center tw-justify-center">
          <mat-icon
            class="loading !tw-w-4 !tw-h-4"
            svgIcon="refresh-line"
          />
        </div>
      } @else {
        {{ 'SIH.SECOND_MODAL.BUTTON2' | translate }}
      }
    </button>
  </div>
  <div class="tw-font-normal tw-text-xs tw-text-gray-400">{{ 'SIH.FIRST_MODAL.NOTE' | translate }}</div>
}
