import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';

import { P2pAuctionCompletedModalModule, P2pSetupModalModule } from './modals';
import { P2PWidgetComponent } from './p2p.component';
import { P2pPurchaseModule } from './p2p-purchase/p2p-purchase.module';
import { P2pSellModule } from './p2p-sell/p2p-sell.module';

@NgModule({
  declarations: [P2PWidgetComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    P2pAuctionCompletedModalModule,
    P2pSetupModalModule,
    P2pSellModule,
    P2pPurchaseModule,
    MatTabsModule,
    MatIconModule,
  ],
  exports: [P2PWidgetComponent],
})
export class P2PWidgetNewModule {
  // static forRoot(engine: TypeProvider): ModuleWithProviders<P2pWidgetNewModule> {
  //   return {
  //     ngModule: P2pWidgetNewModule,
  //     providers: [
  //       {
  //         provide: P2P_ENGINE,
  //         useClass: engine,
  //       },
  //     ],
  //   };
  // }
}
