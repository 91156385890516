import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, input, output } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import type { INotification } from '@dev-fast/types';
import { NotificationType } from '@dev-fast/types';
import { filter, scan, timer } from 'rxjs';

import { AppCurrencyTranslateModule } from '@app/shared/pipes';

import { NOTIFY_DURATION } from '../../constants';
import { ToastService } from '../../toast.service';

const REFRESH_INTERVAL = 200;
const MAX_PERCENT_LOADING = 100;

@Component({
  selector: 'app-notification-item',
  templateUrl: 'notification-item.component.html',
  styleUrls: ['notification-item.component.scss'],
  standalone: true,
  imports: [MatIconModule, AppCurrencyTranslateModule, TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {
  readonly #step = (MAX_PERCENT_LOADING / NOTIFY_DURATION) * REFRESH_INTERVAL;

  readonly #notificationService = inject(ToastService);
  readonly #destroyRef = inject(DestroyRef);

  readonly notification = input.required<INotification>();
  readonly isPaused = input.required<boolean>();
  readonly isLastNotify = input.required<boolean>();
  readonly resumeNotifications = output<void>();

  readonly width = toSignal(
    timer(0, REFRESH_INTERVAL).pipe(
      filter(() => !this.isPaused()),
      scan((acc) => acc + this.#step, 0),
      takeUntilDestroyed(this.#destroyRef),
    ),
    { initialValue: 0 },
  );

  NotificationType = NotificationType;

  deleteNotification({ id }: INotification): void {
    if (this.isLastNotify()) {
      this.resumeNotifications.emit();
    }
    this.#notificationService.deleteNotification(id);
  }

  getIcon(type: NotificationType): string {
    const isInfoIcon = type === NotificationType.Info;
    const isSuccessIcon = type === NotificationType.Success;

    const normType = isInfoIcon || isSuccessIcon ? type : NotificationType.Info;
    return `notification-new-${normType}`;
  }
}
