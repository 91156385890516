import type { Route, Routes } from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { NoIndexComponent, PlugComponent } from '@dev-fast/ui-components';

import { IsAuthGuard, IsAuthNotGuard } from '@app/auth';
import {
  AutoGameActivateChildGuard,
  AutoGameActivateGuard,
  AutoGameDeactivateGuard,
  BanActivateGuard,
  OnlyServerGuard,
} from '@app/core/guards';
import { SeoType } from '@app/core/seo-service';

import { bbConfig } from '../environments/feature-flags';

const getLegacyRoute = (): Route[] => {
  return bbConfig.legacyRoutes.map((route) => ({
    path: route,
    data: { seo: bbConfig.legacySeoKeys[route] },
    component: PlugComponent,
    canDeactivate: [AutoGameDeactivateGuard],
    canActivate: [AutoGameActivateGuard, BanActivateGuard],
    canActivateChild: [AutoGameActivateChildGuard, BanActivateGuard],
  }));
};

export const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('@app/modules/lobby').then((modules) => modules.LobbyModule),
      },
      {
        path: 'hell-case',
        loadComponent: () => import('./pages/hell-case/hell-case.component').then((modules) => modules.HellCaseComponent),
        canActivate: [IsAuthNotGuard],
        data: {
          redirectTo: '/game/cases/case/241',
        },
      },
      {
        path: 'key-drop',
        loadComponent: () => import('./pages/key-drop/key-drop.component').then((modules) => modules.KeyDropComponent),
        canActivate: [IsAuthNotGuard],
        data: {
          redirectTo: '/game/cases/case/245',
        },
      },
      {
        path: 'skin-club',
        loadComponent: () => import('./pages/skin-club/skin-club.component').then((modules) => modules.SkinClubComponent),
        canActivate: [IsAuthNotGuard],
        data: {
          redirectTo: '/game/cases/case/242',
        },
      },
      {
        path: 'csgo-roll',
        loadComponent: () => import('./pages/csgo-roll/csgo-roll.component').then((modules) => modules.CsgoRollComponent),
        canActivate: [IsAuthNotGuard],
        data: {
          redirectTo: '/game/cases/case/244',
        },
      },
      {
        path: 'farm-skins',
        loadComponent: () => import('./pages/farm-skins/farm-skins.component').then((modules) => modules.FarmSkinsComponent),
        canActivate: [IsAuthNotGuard],
        data: {
          redirectTo: '/game/cases/case/243',
        },
      },
      {
        // страница заглушка для пререндера неважных сео страниц
        path: 'noindex',
        canActivate: [OnlyServerGuard],
        canLoad: [OnlyServerGuard],
        component: NoIndexComponent,
      },
      {
        path: 'tos',
        data: { seo: 'TAC', seoType: [SeoType.TITLE, SeoType.DESCRIPTION], noAlternative: true },
        loadChildren: () => import('@app/modules/tos').then((modules) => modules.TosModule),
      },
      {
        path: 'privacy-policy',
        data: { seo: 'PRIVACY_POLICY', seoType: [SeoType.TITLE, SeoType.DESCRIPTION], noAlternative: true },
        loadChildren: () => import('@app/modules/privacy-policy').then((modules) => modules.PrivacyPolicyModule),
      },
      {
        path: 'anti-bribery-policy',
        loadChildren: () => import('@app/modules/anti-bribery-policy').then((modules) => modules.AntiBriberyPolicyModule),
      },
      {
        path: 'aml-policy',
        loadChildren: () => import('@app/modules/aml-policy').then((modules) => modules.AmlPolicyModule),
      },
      {
        path: 'refund-policy',
        loadChildren: () => import('@app/modules/refund-policy').then((modules) => modules.RefundPolicyModule),
      },
      {
        path: 'bug-bounty',
        data: { seo: 'BUGBOUNTY', seoType: [SeoType.TITLE] },
        component: PlugComponent,
      },
      ...getLegacyRoute(),

      {
        path: 'history/fast/all',
        redirectTo: 'history/fast',
        pathMatch: 'full',
      },
      {
        path: 'balance/withdrawal',
        redirectTo: 'withdrawal/method',
        pathMatch: 'full',
      },
      {
        path: 'buy-coins',
        redirectTo: 'refill',
        pathMatch: 'full',
      },
      {
        path: 'cases',
        redirectTo: 'game/cases',
        pathMatch: 'full',
      },
      {
        path: 'classic',
        redirectTo: 'game/classic',
        pathMatch: 'full',
      },
      {
        path: 'fast',
        redirectTo: 'game/fast',
        pathMatch: 'full',
      },
      {
        path: 'double',
        redirectTo: 'game/double',
        pathMatch: 'full',
      },
      {
        path: 'x50',
        redirectTo: 'game/x50',
        pathMatch: 'full',
      },
      {
        path: 'slot',
        redirectTo: 'game/slot',
        pathMatch: 'full',
      },
      {
        path: 'fortune-wheel',
        redirectTo: 'game/fortune-wheel',
        pathMatch: 'full',
      },
      {
        path: 'personal',
        redirectTo: 'account',
        pathMatch: 'full',
      },
      {
        path: 'account/main',
        redirectTo: 'account',
        pathMatch: 'full',
      },
      {
        path: 'personal/balance',
        redirectTo: 'account/balance',
        pathMatch: 'full',
      },
      {
        path: 'market/inventory',
        redirectTo: 'inventory',
        pathMatch: 'full',
      },
      {
        path: 'faucet',
        redirectTo: 'bonuses/quest',
        pathMatch: 'full',
      },
      {
        path: 'bonuses',
        redirectTo: 'bonuses/quest',
        pathMatch: 'full',
      },
      {
        path: 'support',
        redirectTo: 'game',
        pathMatch: 'full',
      },
      {
        path: 'support/requests',
        redirectTo: 'game',
        pathMatch: 'full',
      },
      // NOTE: redirect form bb
      {
        path: 'event',
        redirectTo: 'events',
        pathMatch: 'full',
      },

      {
        path: 'withdrawal',
        redirectTo: '/(modal:transaction/withdrawal)',
        pathMatch: 'full',
      },
      {
        path: 'withdrawal/method',
        redirectTo: '/(modal:transaction/withdrawal)',
        pathMatch: 'full',
      },
      {
        path: 'withdrawal/common',
        redirectTo: '/(modal:transaction/withdrawal)',
        pathMatch: 'full',
      },
      {
        path: 'withdrawal/history',
        redirectTo: '/(modal:transaction/withdrawal/history)',
        pathMatch: 'full',
      },
      {
        path: 'refill/method',
        redirectTo: '/(modal:transaction/refill)',
        pathMatch: 'full',
      },
      {
        path: 'refill/prepaid',
        redirectTo: '/(modal:transaction/refill/giftcards)',
        pathMatch: 'full',
      },
      {
        path: 'refill/history',
        redirectTo: 'account/balance',
        pathMatch: 'full',
      },
      {
        path: 'refill',
        redirectTo: '/(modal:transaction/refill)',
        pathMatch: 'full',
      },
      {
        path: 'refill/skins',
        redirectTo: '/(modal:transaction/refill/skins)',
        pathMatch: 'full',
      },
      {
        path: 'game',
        loadChildren: () => import('@app/games').then((modules) => modules.GamesModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, BanActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'faq',
        loadChildren: () => import('@app/modules/faq-home').then((modules) => modules.FaqHomeModule),
        data: { seo: 'FAQ' },
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, BanActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'store',
        loadChildren: () => import('@app/modules/p2p-new').then((modules) => modules.P2pNewModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, BanActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      // {
      //   path: 'transaction',
      //   loadChildren: () => import('@app/modules/transaction').then((modules) => modules.TransactionModule),
      //   canDeactivate: [AutoGameDeactivateGuard],
      //   canActivate: [AutoGameActivateGuard, IsAuthGuard, BanActivateGuard],
      //   canActivateChild: [AutoGameActivateChildGuard],
      // },
      {
        path: 'inventory',
        loadChildren: () => import('@app/modules/inventory').then((modules) => modules.InventoryModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, BanActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      // {
      //   path: 'provably-fair',
      //   loadChildren: () => import('@app/modules/provably-fair').then((modules) => modules.ProvablyFairModule),
      //   data: { requiredFeatureFlags: ['provably-fair'] },
      //   canDeactivate: [AutoGameDeactivateGuard],
      //   canActivate: [AutoGameActivateGuard, BanActivateGuard],
      //   canActivateChild: [AutoGameActivateChildGuard],
      // },
      {
        path: 'skins',
        loadChildren: () => import('@app/modules/skins-new').then((modules) => modules.SkinsNewModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, BanActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'account',
        loadChildren: () => import('@app/modules/account').then((modules) => modules.AccountModule),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, IsAuthGuard, BanActivateGuard],
      },
      {
        path: 'events',
        loadChildren: () => import('@app/modules/events').then(({ routes: eventRoutes }) => eventRoutes),
        canDeactivate: [AutoGameDeactivateGuard],
        canActivate: [AutoGameActivateGuard, BanActivateGuard],
        canActivateChild: [AutoGameActivateChildGuard],
      },
      {
        path: 'fail-payment',
        children: [
          {
            path: ':id',
            redirectTo: '/(modal:fail-payment/:id)',
          },
          {
            path: '**',
            redirectTo: '/',
          },
        ],
      },
      {
        path: 'success-payment',
        children: [
          {
            path: ':id',
            redirectTo: '/(modal:success-payment/:id)',
          },
          {
            path: '**',
            redirectTo: '/',
          },
        ],
      },

      {
        path: 'block',
        component: PlugComponent,
      },
    ],
  },
];
