@if (direction(); as direction) {
  <div class="tw-flex tw-flex-col tw-flex-1">
    @if (direction === 'vertical') {
      <div class="text-gray-400 tw-font-normal tw-text-xs">{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.YOU_GIVING' | translate }}</div>
    }
    <ng-scrollbar
      class="hide-scroll mask"
      [track]="direction"
    >
      <div
        class="tw-flex tw-gap-2 tw-py-3"
        [class.tw-flex-col]="direction === 'vertical'"
        [class.tw-w-min]="direction === 'horizontal'"
        [class.tw-m-auto]="direction === 'horizontal'"
        #itemsContainer
      >
        @for (lotItem of items(); track $index) {
          @if (itemColorPalette(lotItem); as palette) {
            <div
              class="item tw-cursor-pointer tw-rounded-xl tw-overflow-hidden tw-min-w-72 tw-h-full"
              [class.active-item]="lotItem.id === currentItem()?.id"
              [class.mobile-item]="direction === 'horizontal'"
              [class.tw-w-max]="direction === 'horizontal'"
            >
              <app-ui-p2p-lot-card
                class="tw-p-2"
                [style.--bg-color]="palette.default"
                [style.--hover-color]="palette.hover"
                (click)="setItemActive.emit(lotItem.id)"
                [items]="[lotItem]"
                [isSell]="true"
              />
              @if (direction === 'vertical') {
                <div class="place tw-flex text-xxs tw-bg-gray-550">
                  <div class="tw-font-bold tw-text-gray-100 tw-bg-blue-500 tw-flex tw-justify-center tw-items-center tw-w-7 tw-h-6">
                    {{ lotItem.indicator }}
                  </div>
                  <div class="tw-flex tw-w-full tw-h-full tw-justify-center tw-m-auto text-gray-400">
                    {{ lotItem.page }}
                    {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_1' | translate }}
                    {{ lotItem.line }}
                    {{ 'P2P_WIDGETS.DEPOSITING.ITEM_LOCATION_PHRASE_2' | translate }}
                    {{ lotItem.number }}
                  </div>
                  @if (isMobileView()) {
                    <div
                      class="tw-font-bold text-xxs tw-text-gray-100 tw-bg-blue-500 tw-p-2 tw-whitespace-nowrap tw-self-center"
                      (click)="openInventoryPanel.emit()"
                    >
                      {{ 'P2P_WIDGETS.SIH_TRADE.INVENTORY' | translate }} >
                    </div>
                  }
                </div>
              }
            </div>
          }
        }
      </div>
    </ng-scrollbar>
  </div>
}
