import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, input, Output, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup } from '@angular/forms';
import { P2pDepositService } from '@core/p2p-services/p2p-deposit.service';
import {
  FAST_SELL_OVERPRICE,
  IP2pDepositItem,
  IP2pDepositSettings,
  IUserP2pPermissionInterface,
  SLOW_SELL_OVERPRICE,
  SteamErrorsEnum,
  SteamErrorsEnumLocales,
  VERY_SLOW_SELL_OVERPRICE,
} from '@dev-fast/types';
import { round } from 'lodash';
import { debounceTime, pairwise, startWith, tap } from 'rxjs';

@Component({
  selector: 'app-p2p-sell-setup-panel',
  templateUrl: './p2p-sell-setup-panel.component.html',
  styleUrls: ['./p2p-sell-setup-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P2pSellSetupPanelComponent {
  #service = inject(P2pDepositService);

  @Input() lotDepositSettings: IP2pDepositSettings | null = null;
  @Input() overprice: number | null = null;

  @Input() set userPermissions(permissions$: IUserP2pPermissionInterface) {
    if (permissions$) {
      this.permissions = permissions$;
      this.permissionErrorBtnText = this.formatErrBtnText(permissions$);
      this.isActionBtnDisabled = permissions$?.canTrade?.error === SteamErrorsEnum.STEAM_TRADE_BAN;
    }
  }

  @Input() selectedSum: number | null = null;
  @Input() selectedSumWithOverprice: number | null = null;

  @Output() panelClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() sellSelected: EventEmitter<void> = new EventEmitter<void>();
  @Output() setupSteam: EventEmitter<void> = new EventEmitter<void>();
  @Output() setOverprice: EventEmitter<number> = new EventEmitter<number>();
  @Output() reqAutoSelectItems: EventEmitter<number> = new EventEmitter<number>();
  @Output() itemRemoved: EventEmitter<IP2pDepositItem> = new EventEmitter<IP2pDepositItem>();
  @Output() itemsUpdated: EventEmitter<IP2pDepositItem[]> = new EventEmitter<IP2pDepositItem[]>();
  @Output() settingsChanged: EventEmitter<IP2pDepositSettings> = new EventEmitter<IP2pDepositSettings>();
  @Output() goToSteamInventoryMarketTab: EventEmitter<void> = new EventEmitter<void>();

  selectedItems = input<IP2pDepositItem[]>();
  // TODO Это все можно переложить на стейт, если переделать #formatExtraForItem в депозит стейте
  newSelectedItems = toObservable(this.selectedItems).pipe(
    startWith([]),
    pairwise(),
    tap(([prevSelectedItems, currSelectedItems]) => {
      if (!currSelectedItems) {
        return;
      }

      const newItems =
        currSelectedItems?.filter((newItem) => {
          return !prevSelectedItems?.some((oldItem) => {
            return newItem.steamInventoryId === oldItem.steamInventoryId;
          });
        }) || [];

      if (newItems.length) {
        const result = currSelectedItems.map((item) => {
          const isItemNew = newItems.some((newItem) => newItem.steamInventoryId === item.steamInventoryId);
          if (isItemNew) {
            this.#service.setDepositById({
              ...item,
              extra: {
                increase: item.extra.safeOverprice || 0,
                price: item.safePrice || round(item.price * 0.95), // если safePrice нет, то выставляем с -5%
                safeOverprice: item.extra.safeOverprice,
              },
            });
            return {
              ...item,
              extra: {
                increase: item.extra.safeOverprice || 0,
                price: item.safePrice || round(item.price * 0.95), // если safePRice нет, то выставляем с -5%
                safeOverprice: item.extra.safeOverprice,
              },
            };
          } else {
            return item;
          }
        });
        const safeOverpriceSum = result.reduce((sum, curr) => {
          sum += curr.extra.increase || 0;
          return sum;
        }, 0);

        this.sellSettingsForm.controls['overprice'].setValue(Math.floor(safeOverpriceSum / currSelectedItems.length), { emitEvent: false });

        this.itemsForSale.set(result);
      } else {
        this.itemsForSale.set(currSelectedItems);
      }
    }),
    takeUntilDestroyed(),
  );

  itemsForSale = signal<IP2pDepositItem[]>([]);

  constructor() {
    this.newSelectedItems.subscribe();

    this.sellSettingsForm.valueChanges
      .pipe(
        debounceTime(100),
        tap((val) => {
          this.setOverprice.emit(val.overprice ? val.overprice : 0);
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  sellSettingsForm: FormGroup = new FormGroup({
    overprice: new FormControl<number | null>(this.overprice),
  });

  permissions: IUserP2pPermissionInterface | null = null;
  permissionErrorTooltip = '';
  permissionErrorBtnText = '';
  isActionBtnDisabled = false;
  // Enums
  readonly steamErrorsEnum = SteamErrorsEnum;

  get currentOverprice(): number {
    return this.sellSettingsForm.controls['overprice'].value;
  }

  get currentOverpriceColor(): 'var(--color-green-500)' | 'var(--color-red-500)' | 'var(--color-yellow-500)' | '#fa8200' {
    const overprice = this.sellSettingsForm.controls['overprice'].value;
    switch (true) {
      case overprice < FAST_SELL_OVERPRICE:
        return 'var(--color-green-500)';
      case overprice > VERY_SLOW_SELL_OVERPRICE:
        return 'var(--color-red-500)';
      case overprice > SLOW_SELL_OVERPRICE:
        return '#fa8200';
      default:
        return 'var(--color-yellow-500)';
    }
  }

  updateAutoSelected(currency: number): void {
    this.setOverprice.emit(this.overprice ? this.overprice : 0);
    this.reqAutoSelectItems.emit(currency);
  }

  updateTradeSettings(newValue: IP2pDepositSettings): void {
    this.lotDepositSettings = newValue;
    this.settingsChanged.emit(this.lotDepositSettings);
  }

  goToInventoryMarketTab = (): void => this.goToSteamInventoryMarketTab.emit();
  removeSelectedItem = (item: IP2pDepositItem): void => this.itemRemoved.emit(item);
  onSetupSteam = (): void => this.setupSteam.emit();
  onPanelClose = (): void => this.panelClosed.emit();
  sellSelectedItems = (): void => this.sellSelected.emit();

  formatLabel(value: number): string {
    return value + ' %';
  }

  formatErrBtnText(permissions: IUserP2pPermissionInterface): string {
    let title: string | undefined = 'P2P_SETTINGS.SETUP_TITLE';
    const errorMsg = permissions?.canTrade?.error || permissions?.canSteamAPI?.error || permissions?.error;
    if (errorMsg) {
      title = SteamErrorsEnumLocales[errorMsg];
    }
    return title ?? 'P2P_SETTINGS.SETUP_TITLE';
  }

  handleErrorAction(permissions: IUserP2pPermissionInterface | null): void {
    if (!permissions) {
      return;
    }
    const errorMsg = permissions.canTrade?.error || permissions.canSteamAPI?.error || permissions.error;
    switch (errorMsg) {
      case SteamErrorsEnum.UNBINDING_STEAM:
        window.open('/account/main', '_blank');
        break;
      case SteamErrorsEnum.STEAM_GUARD:
        window.open('https://store.steampowered.com/mobile', '_blank');
        break;
      case SteamErrorsEnum.PRIVATE_INVENTORY:
        window.open('https://steamcommunity.com/my/edit/settings', '_blank');
        break;
      case SteamErrorsEnum.STEAM_TRADE_BAN:
      case SteamErrorsEnum.STEAM_GUARD_HOLD:
        break;
      case SteamErrorsEnum.INVALID_TRADELINK:
      case SteamErrorsEnum.NO_API_KEY:
      default:
        this.onSetupSteam();
        break;
    }
  }
}
