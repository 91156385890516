import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { UiAcceptSAComponent } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { LangRouterModule } from '@app/shared/directives';

import { P2pPurchaseComponent } from './p2p-purchase.component';
import { P2pPurchaseService } from './p2p-purchase.service';
import { P2pPurchaseItemModule } from './p2p-purchase-item/p2p-purchase-item.module';

@NgModule({
  declarations: [P2pPurchaseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    LangRouterModule,
    TranslateModule,
    NgScrollbarModule,
    P2pPurchaseItemModule,
    MatIconModule,
    UiAcceptSAComponent,
  ],
  exports: [P2pPurchaseComponent],
  providers: [P2pPurchaseService],
})
export class P2pPurchaseModule {}
