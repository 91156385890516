@if (user) {
  @switch (getTemplateType(user)) {
    @case ('level') {
      <div class="chat-info-container">
        <span>{{ 'CHAT_WIDGET.RESTRICTION' | translate }}</span>
      </div>
    }
    @case ('banned') {
      <div class="chat-info-container">
        @if (chatAccess && chatAccess.banReason) {
          <span>{{ chatAccess.banReason | translate }}</span>
        }
        @if (chatAccess && chatAccess.bannedUntil) {
          <span>{{ 'CHAT_WIDGET.BANNED' | translate }} {{ banTimer }}</span>
        }
      </div>
    }
    @case ('login') {
      <div class="chat-info-container">
        <button class="btn btn-primary btn-lg login-button">{{ 'LOGIN' | translate }}</button>
      </div>
    }
    @case ('access') {
      <div class="h-100 stickers-container">
        <form
          class="message message__block"
          [formGroup]="messageForm"
        >
          <!-- <mat-form-field class="chat-input-area">
          <input
            matInput
            maxlength="140"
            formControlName="message"
            autocomplete="off"
            (keydown.enter)="onAddMessage($event)"
            />
        </mat-form-field> -->
          <label class="message__wrapper">
            <input
              type="text"
              class="message__input"
              placeholder="Message..."
              matInput
              maxlength="140"
              formControlName="message"
              autocomplete="off"
              (keydown.enter)="onAddMessage($event)"
            />
          </label>
          <!-- <div class="chat-buttons-container">
        <button type="button"
          class="btn btn-sm btn-dark stickers-button"
          (click)="toggleStickers()"
          [ngClass]="{ 'open': stickersIsOpen }"
          >
          <span class="icomoon icon-smile"></span>
        </button>
        <button
          class="btn btn-sm chat-footer__btn chat-footer__btn_send"
          type="submit"
          [disabled]="!messageForm.valid"
          >
          <span class="icomoon icon-send"></span>
          <mat-icon
            svgIcon="telegram"
            class="chat-footer__btn_icon"
          ></mat-icon>
        </button>
      </div> -->
          <div class="message__buttons">
            <!-- <button
        class="chat-footer__btn chat-footer__btn_sticker"
        [ngClass]="{ open: stickersIsOpen }"
        (click)="toggleStickers()"
        >
        <mat-icon
          svgIcon="smile"
          class="chat-footer__btn_icon"
        ></mat-icon>
      </button> -->
            <button
              class="chat-footer__btn chat-footer__btn_send"
              (click)="onAddMessage($event)"
              [disabled]="!messageForm.valid"
            >
              <mat-icon
                svgIcon="telegram"
                class="chat-footer__btn_icon"
              ></mat-icon>
            </button>
          </div>
        </form>
        <!-- <div
  class="chat-stickers-panel"
  *ngIf="stickersIsOpen && stickers"
  >
  <div class="chat-stickers-tabs">
    <button
      class="chat-stickers-tab btn btn-dark btn-sm active"
      [ngClass]="{ active: tab.name === activeStickerPack }"
      *ngFor="let tab of stickers"
      (click)="changeActiveTabWithRelocate(tab.name)"
      >
      <img
        class="chat-stickers-tab-image"
        [src]="getStickerPath(tab, 'previews', 0)"
        [alt]="tab.name"
        />
    </button>
  </div>
  <div class="chat-stickers-wrapper">
    <ng-scrollbar [config]="{ suppressScrollX: true, wheelPropagation: false }">
      <div
        class="chat-stickers-list"
        #stickersWrapper
        >
        <app-stickers
          *ngFor="let pack of stickers"
          [attr.data-id]="pack.name"
          [stickers]="pack"
          (stickerClick)="addSticker($event)"
        ></app-stickers>
      </div>
    </ng-scrollbar>
  </div>
</div> -->
      </div>
    }
  }
}

<!-- <form
[formGroup]="messageForm"
class="message message__block"
>
<label class="message__wrapper">
  <input
    type="text"
    class="message__input"
    placeholder="Message..."
    formControlName="message"
    />
</label> -->
<!-- <div class="message__buttons">
<button class="chat-footer__btn chat-footer__btn_sticker"

  (click)="onToggleStickerContainer()"
  >
  <mat-icon
    svgIcon="smile"
    class="chat-footer__btn_icon"
  ></mat-icon>
</button>
<button class="chat-footer__btn chat-footer__btn_send"
  (click)="sendMessage()"
  >
  <mat-icon
    svgIcon="telegram"
    class="chat-footer__btn_icon"
  ></mat-icon>
</button>
</div> -->
<!-- </form> -->
