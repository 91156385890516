@if (cardNavigateUrl(); as navigateUrl) {
  <a
    langRouterLink
    [routerLink]="navigateUrl"
    [routerLinkActive]="'active'"
    [class.active]="isActive()"
    class="tw-flex tw-flex-col !tw-no-underline hover:tw-scale-110 tw-duration-200"
  >
    @if (cardImgSrc(); as cardImgSrc) {
      <span class="tw-flex tw-flex-col tw-relative">
        <img
          [src]="cardImgSrc"
          alt=""
          class="game-icon tw-block tw-size-full tw-my-0 tw-mx-auto tw-rounded-xl tw-shadow-[0_0.25rem_0.5rem_rgba(0,0,0,0.1)]"
        />
        <!-- Сюда можно передать какой-то кастомный тег -->
        <ng-content></ng-content>
      </span>
    }
    @if (titleType() === 'bottom') {
      <span class="tw-font-normal tw-text-xs tw-leading-[150%] tw-text-center">{{ cardHoverTitle() }}</span>
    }
  </a>
}
