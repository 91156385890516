import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { IGame, IMessage, IStickerMessage } from '@dev-fast/types';
import moment from 'moment';

import { isMoreThanDay } from '@app/shared/utils';

@Component({
  selector: 'app-chat-item',
  templateUrl: './chat-item.component.html',
  styleUrls: ['./chat-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatItemComponent {
  @Input() message: IMessage | undefined;
  @Input() availableGames: IGame[];
  @Input() isMyMessage = false;
  constructor(private readonly cdr: ChangeDetectorRef) {
    this.availableGames = [];
  }
  getDate(date: Date | number): string {
    return `${isMoreThanDay(Date.now(), date) ? moment(date).format('DD.MM.YY (HH:mm)') : moment(date).format('HH:mm')}`;
  }
  checkMessageType(message: string | IStickerMessage): boolean {
    return typeof message === 'string';
  }

  getSticker(value: string | IStickerMessage): string {
    if (typeof value !== 'string') {
      return `${value.path}/pack/${value.id}.png`;
    }
    return '';
  }
}
