import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, TypeProvider } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NotFoundStubModule } from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ClickOutsideModule } from '@app/shared/directives';
import { AppCurrencyTranslateModule } from '@app/shared/pipes';

import { NotificationsComponent } from './notifications.component';
import { NOTIFICATIONS_ENGINE } from './symbols';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    NotFoundStubModule,
    NgScrollbarModule,
    TranslateModule,
    AppCurrencyTranslateModule,
    ClickOutsideModule,
    InViewportModule,
  ],
  declarations: [NotificationsComponent],
  exports: [NotificationsComponent],
})
export class WidgetsNotificationsNewModule {
  static forRoot(engine: TypeProvider): ModuleWithProviders<WidgetsNotificationsNewModule> {
    return {
      ngModule: WidgetsNotificationsNewModule,
      providers: [
        {
          provide: NOTIFICATIONS_ENGINE,
          useClass: engine,
        },
      ],
    };
  }
}
