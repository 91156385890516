@if (data$ | async; as lot) {
  <div class="p2p-trade-accept modal-wrapper">
    <div class="modal-wrapper__header">
      <div class="modal-wrapper__header-title">
        <span>{{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.YOU_WON' | translate }}</span>
        <button
          class="btn-close"
          (click)="onClose()"
        >
          <mat-icon svgIcon="close"> </mat-icon>
        </button>
      </div>
    </div>
    <div class="p2p-trade-accept__item">
      <div class="text-sm text-muted mb-sm-3">{{ 'Вы получаете' | translate }}</div>
      <app-ui-p2p-lot-card
        class="container-dark p-sm-3 mb-sm-3"
        [items]="lot.items"
      ></app-ui-p2p-lot-card>
      <div class="timer text-sm text-muted">
        <mat-icon svgIcon="clock--design"></mat-icon>
        <span>{{ 'P2P_WIDGETS.AUCTION_COMPLETED_DIALOG.AUCTION_UNTIL_TIME' | translate }}</span>
        @if ({ timer: offerTimer$ | async }; as offer) {
          <span class="time-left">
            {{ offer.timer && offer.timer > 1000 ? (offer.timer | date: 'mm:ss') : '00:00' }}
          </span>
        }
      </div>
    </div>
    <div class="warning-banner text-sm">
      <div class="warning-banner__icon"><mat-icon svgIcon="danger-sign--design"></mat-icon></div>
      <div class="warning-banner__message">
        <div class="warning-banner__message-body">
          {{ 'P2P_WIDGETS.WARN.DELIBERATELY_BREAKDOWN_OFFER' | translate }}.
          {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_TITLE' | translate }}.
          {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.ALERT_DESCRIBE' | translate }}
        </div>
      </div>
    </div>
    @if ({ timer: offerTimer$ | async }; as offer) {
      <a
        class="btn btn-primary--design w-100"
        href="https://steamcommunity.com/id/me/tradeoffers"
        target="_blank"
        [class.disabled]="!offer.timer || offer.timer < 1000"
      >
        {{ 'P2P_WIDGETS.PURCHASE_TRADE_DIALOG.OPEN_TRADE' | translate }}
      </a>
    }
  </div>
}
