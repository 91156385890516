import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfigConfirmDialog } from './config';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfigConfirmDialog,
    private mdDialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {}
  cancel(): void {
    this.close(false);
  }
  close(value: boolean): void {
    this.mdDialogRef.close(value);
  }
  confirm(): void {
    this.close(true);
  }
  @HostListener('keydown.esc')
  onEsc(): void {
    this.close(false);
  }
}
