import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import type { IFormattedMarketplaceItem } from '@dev-fast/types';
import { TranslateModule } from '@ngx-translate/core';

import { ImageErrorHandlerDirective } from '@app/shared/directives/image-error-handler/image-error-handler.directive';
import { HashPipe } from '@app/shared/pipes';

@Component({
  standalone: true,
  selector: 'app-steam-inventory-position',
  templateUrl: './steam-inventory-position.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, HashPipe, TranslateModule, MatIconModule, ImageErrorHandlerDirective],
})
export class SteamInventoryPositionComponent {
  pages = input<number[]>();
  currentSteamItem = input<IFormattedMarketplaceItem>();
  currentSteamInventoryPage = input<IFormattedMarketplaceItem[]>();

  setItemActive = output<number>();
  setPageActive = output<number>();

  pagesSorted = computed(() => {
    const pages = this.pages();
    if (!pages) {
      return;
    }
    return new Int32Array(pages).sort();
  });

  pagesToShow = computed(() => {
    const currentSteamItem = this.currentSteamItem();
    const sortedPages = this.pagesSorted();

    if (!currentSteamItem || !sortedPages) {
      return;
    }
    const pagesToShow: number[] = [];

    if (sortedPages.length > 6) {
      const itemPageIndexOf = sortedPages.indexOf(currentSteamItem.page);

      if (itemPageIndexOf + 4 > sortedPages.length) {
        pagesToShow.push(...sortedPages.slice(sortedPages.length - 6, sortedPages.length));
      } else if (itemPageIndexOf - 2 > 0) {
        pagesToShow.push(...sortedPages.slice(itemPageIndexOf - 2, itemPageIndexOf + 4));
      } else {
        pagesToShow.push(...sortedPages.slice(0, 6));
      }

      return pagesToShow;
    }
    pagesToShow.push(...sortedPages);
    while (pagesToShow.length < 5) {
      pagesToShow.push(pagesToShow[pagesToShow.length - 1] + 1);
    }
    return pagesToShow;
  });
}
