import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import {
  CountdownComponentModule,
  CubeGridLoaderComponentModule,
  IncreaseModule,
  PaginationNewModule,
  SkinItemNewModule,
  UserAvatarModule,
} from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';
import { InViewportModule } from 'ng-in-viewport';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NgScrollbarReachedModule } from 'ngx-scrollbar/reached-event';

import { CurrencyComponent } from '@app/core/currency';
import { ClickOutsideModule, IsProjectModule, LangRouterModule, ModuleIsOnModule } from '@app/shared/directives';
import { AppCurrencyModule, CustomAsyncPipeModule } from '@app/shared/pipes';

import { ActionMenuComponent } from './action-menu.component';
import { DesktopAppActionMenuService } from './action-menu.service';
import { LanguagePanelComponent } from './language-panel/language-panel.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';

@NgModule({
  declarations: [MenuPanelComponent, ActionMenuComponent],
  exports: [MenuPanelComponent, LanguagePanelComponent, ActionMenuComponent],
  providers: [DesktopAppActionMenuService],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatIconModule,
    MatRadioModule,
    FormsModule,
    UserAvatarModule,
    AppCurrencyModule,
    ClickOutsideModule,
    SkinItemNewModule,
    ReactiveFormsModule,
    NgScrollbarModule,
    NgScrollbarReachedModule,
    CustomAsyncPipeModule,
    CubeGridLoaderComponentModule,
    PaginationNewModule,
    IncreaseModule,
    CountdownComponentModule,
    LangRouterModule,
    ModuleIsOnModule,
    IsProjectModule,
    CurrencyComponent,
    InViewportModule,
    LanguagePanelComponent,
  ],
})
export class DesktopAppActionMenuModule {}
