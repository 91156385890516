import { GameMode, IGame } from '@dev-fast/types';

export const getDictGames = (payload: IGame[] | null): undefined | Partial<Record<GameMode, IGame[]>> => {
  if (!payload) {
    return;
  }
  return payload.reduce(
    (acc, game) => {
      const tempValue = acc[game.mode];
      acc[game.mode] = tempValue ? [...tempValue, game] : [game];
      return acc;
    },
    {} as Partial<Record<GameMode, IGame[]>>,
  );
};
export const getModes = (gamesDict: Partial<Record<GameMode, IGame[]>>): GameMode[] => {
  return Object.values(GameMode).filter((value) => {
    return Object.keys(gamesDict).includes(value);
  });
};
