import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { UiCheckboxSAComponent } from '@app/ui/components/index';
import { SIH_LINK, SihService } from '@app/widgets/sih';

@Component({
  standalone: true,
  selector: 'app-sih-confirm-panel',
  templateUrl: './sih-confirm-panel.component.html',
  styleUrl: '../../shared-styles.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SihService],
  imports: [TranslateModule, MatIconModule, UiCheckboxSAComponent],
})
export class SIHConfirmPanelComponent {
  readonly #sihService = inject(SihService);

  readonly sihLink = SIH_LINK;

  readonly loading = toSignal(this.#sihService.checkUserLoading$);
  readonly isSihNotChecked = signal<boolean>(true);

  // sihAutoTradeForm: FormGroup = new FormGroup({
  //   autoMode: new FormControl(true, {
  //     nonNullable: true,
  //   }),
  // });

  onCheckSihInstall(): void {
    this.#sihService.checkUser();
  }

  onRequestPermissions(): void {
    this.#sihService.checkUser();
    this.isSihNotChecked.set(false);
  }
}
