import type { Portal } from '@angular/cdk/portal';
import { TemplatePortal } from '@angular/cdk/portal';
import type { AfterViewInit, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, ViewChild, viewChild, ViewContainerRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { P2pMarketService } from '@core/p2p-services/p2p-market.service';
import type { BreakpointsTypes, FooterCounts, GameMode, IGame, MarketCounts, MenuItem } from '@dev-fast/types';
import { ControlStateEnum, ModalNames, NewMenu, NewPanel } from '@dev-fast/types';
import type { Observable } from 'rxjs';
import { combineLatest, filter, map, tap } from 'rxjs';

import { ChatService } from '@app/core/chatra-service';
import { IFrameCommonService } from '@app/core/iframe';
import { LayoutTypeService } from '@app/core/layout-service';
import { PortalService } from '@app/core/portal-service';
import type { ResizedEvent } from '@app/shared/directives';
import { IS_SERVER_TOKEN } from '@app/shared/utils';

import { GofastLayoutService } from './services/gofast-layout.service';
import { MainModalsService } from './services/main-modals.service';

@Component({
  selector: 'ui-gofast-layout',
  templateUrl: './gofast-layout.component.html',
  styleUrls: ['./gofast-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GofastLayoutComponent implements OnInit, AfterViewInit {
  readonly isServer = inject<boolean>(IS_SERVER_TOKEN);
  readonly #router = inject(Router);
  readonly #viewContainerRef = inject(ViewContainerRef);
  //services
  readonly #moduleService = inject(GofastLayoutService);
  readonly #iFrameService = inject(IFrameCommonService);
  readonly #layoutService = inject(LayoutTypeService);
  readonly #modalsService = inject(MainModalsService);
  readonly #portalService = inject(PortalService);
  readonly #chatService = inject(ChatService);
  readonly #marketService = inject(P2pMarketService);

  // todo: Пока так по тупому, похорошему надо layout в порядок привести
  showGameMenu = toSignal<boolean>(
    this.#router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.#router.url),
      map((url) => {
        const hideMenuResource = ['hell-case', 'key-drop', 'skin-club', 'csgo-roll', 'farm-skins'];
        return !hideMenuResource.some((resource) => url.includes(resource));
      }),
    ),
  );

  showBottomBaner = toSignal<boolean>(
    this.#router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      map(() => this.#router.url),
      map((url) => {
        const hideMenuResource = ['hell-case', 'key-drop', 'skin-club', 'csgo-roll', 'farm-skins'];
        return !hideMenuResource.some((resource) => url.includes(resource));
      }),
    ),
  );

  readonly #marketSuccessfullLots = toSignal(
    combineLatest([this.#marketService.p2pActionsToNotify$, this.#moduleService.panels$]).pipe(
      map(([notys, panels]) => {
        return panels.includes(NewPanel.TRADES) ? 0 : notys;
      }),
    ),
  );

  readonly dictAvailableGames$: Observable<Partial<Record<GameMode, IGame[]>> | undefined> = this.#moduleService.dictAvailableGames$;
  readonly lastGames$: Observable<IGame[]> = this.#moduleService.lastGames$;

  readonly panels$: Observable<NewPanel[]> = this.#moduleService.panels$.pipe(
    tap((value) => {
      this.panels = value;
    }),
  );

  readonly online$: Observable<number> = this.#moduleService.online$;
  readonly curLegacyGame$: Observable<string | null> = this.#moduleService.curLegacyGame$;

  readonly bottomControlClass$: Observable<string> = this.panels$.pipe(
    map((panels) => (panels.includes(NewPanel.BET) ? 'bottom-control-panel--bet__opened' : '')),
  );

  readonly counts$: Observable<FooterCounts> = this.#moduleService.counts$;
  readonly marketCounts$: Observable<MarketCounts> = this.#moduleService.marketCounts$;

  readonly fadeSides$ = combineLatest([this.#iFrameService.isIFrameLoaded$, this.#moduleService.isFadeSides$]).pipe(
    map((data) => data.every(Boolean)),
  );

  readonly sectionHeaderPortal$: Observable<Portal<any> | undefined> = this.#portalService.sectionHeaderPortal.value$;
  readonly breakpoints$: Observable<BreakpointsTypes | null> = this.#moduleService.breakpoints$;
  readonly gameSelectorOpened$: Observable<boolean> = this.#moduleService.gameSelectorOpened$;

  readonly menu$: Observable<NewMenu[]> = this.#moduleService.activeMenu$;

  private panels: NewPanel[] = [];

  readonly rightControls: MenuItem[] = [
    {
      icon: 'cart-renew',
      key: 'cart',
      label: 'Cart',
      notificationCount: this.#marketSuccessfullLots,
      callback: () => {
        this.#onCart();
      },
    },
    {
      icon: 'chat-renew',
      key: 'chat',
      label: 'Chat',
      callback: () => {
        this.#onChat();
      },
    },
    {
      icon: 'headphones-renew',
      key: 'chatra',
      label: 'Chatra',
      callback: () => {
        this.openTechSupportDialog();
      },
      status: ControlStateEnum.ENABLE,
    },
  ];

  headerAnchor = viewChild<ElementRef>('headerAnchor');

  @ViewChild('modalTmpl') modalTmpl: TemplateRef<unknown> | undefined;

  toggleRightPanel(panel: NewPanel): void {
    if (this.panels.includes(panel)) {
      this.#moduleService.closePanel(panel);
    } else {
      if (this.panels.length > 0) {
        this.#moduleService.closePanel(this.panels[0]);
      }
      this.#moduleService.openPanel(panel);
    }
  }

  // отставил пока, не успел разобраться нужно или нет
  onResize(event: ResizedEvent): void {
    if (!this.isServer) {
      this.#layoutService.setBreakpoints(event.newRect.width);
    }
  }

  ngOnInit(): void {
    this.#modalsService.registerModals();
    this.#moduleService.init();
  }

  ngAfterViewInit(): void {
    if (this.modalTmpl) {
      this.#portalService.routableModalPortal.value = new TemplatePortal(this.modalTmpl, this.#viewContainerRef, {});
    }
  }

  onClickLockedSide(): void {
    this.#moduleService.clickOnLockedSide();
  }

  onOpenGleamModal(): void {
    this.#moduleService.openModal(ModalNames.GLEAM);
  }

  isOpenRightPanel(panel: NewPanel[] | null): boolean {
    return !!panel && (panel.includes(NewPanel.TRADES) || panel.includes(NewPanel.CHAT));
  }

  #onCart(): void {
    this.#router.navigate(['/store']);
    this.toggleRightPanel(NewPanel.TRADES);
  }

  #onChat(): void {
    this.toggleRightPanel(NewPanel.CHAT);
  }

  openTechSupportDialog(): void {
    if (this.#chatService.hasChat()) {
      this.#chatService.openChat();
      return;
    }
    this.#moduleService.openModal(ModalNames.TECH_SUPPORT_SELECT_METHOD);
  }

  scrollToTop(): void {
    this.headerAnchor()?.nativeElement.scrollIntoView();
  }

  openLangMenu(): void {
    this.scrollToTop();
    this.#moduleService.openMenu(NewMenu.LANGUAGE);
  }
}
