@if (item) {
  <div
    class="selectedItem-wrapper tw-rounded-2xl tw-relative"
    [style.--bg-color]="bgColor"
    [style.--hover-color]="hvColor"
  >
    <div class="background-wrapper tw-absolute tw-rounded-2xl tw-w-full tw-h-full"></div>
    <div
      class="close-btn tw-absolute tw-cursor-pointer"
      (click)="removeAt()"
    >
      <mat-icon svgIcon="close" />
    </div>
    <div class="selectedItem tw-flex tw-items-center tw-gap-3 tw-relative tw-p-4 tw-pointer-events-none">
      <div class="tw-flex tw-items-center tw-justify-center tw-self-stretch tw-gap-3 tw-relative tw-min-w-20">
        <div class="stickers tw-flex tw-justify-end tw-flex-wrap tw-absolute tw-top-0 tw-left-0 tw-z-10">
          @for (sticker of item.stickers; track sticker) {
            <div class="stickers__item">
              @if (sticker.icon) {
                <img
                  alt=""
                  [src]="sticker.icon | stickerHash"
                />
              }
            </div>
          }
        </div>
        <img
          errorHandler
          class="selectedItem-img"
          [src]="item.baseItem.icon | hash"
          alt=""
        />
      </div>
      <div class="itemInfo flex fx-column gap-2">
        <div class="itemInfo-skinName tw-font-medium">
          @if (item.baseItem.skin) {
            {{ item.baseItem.skin }}
          } @else {
            {{ item.baseItem.name }}
          }
        </div>
        <app-ui-float-indicator
          class="itemInfo-float tw-font-normal color-gray-200"
          [item]="item"
        />
        <div class="itemInfo-lotInfo flex fx-align-center flex-wrap">
          <div class="itemInfo-lotInfo-price flex fx-justify-center gap-1 p-xs-5 tw-font-medium">
            <app-currency
              class="currency"
              [value]="item.extra.price"
            />
            @if (item.extra.increase) {
              <div
                class="modifier"
                [style.color]="increaseFieldColorClass(item.extra.increase, item.extra.safeOverprice)"
              >
                @if (item.extra.increase > 0) {
                  +
                }
                {{ item.extra.increase }}%
              </div>
            }
          </div>
          @if (duration) {
            <div class="pl-sm-1 color-gray-200 tw-font-normal">{{ duration }} {{ 'P2P_WIDGETS.DEPOSITING.TIME' | translate }}</div>
          }
        </div>
      </div>
    </div>
  </div>
}
