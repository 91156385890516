@if (!isServer) {
  @if (
    {
      user: user$ | async,
      panels: panels$ | async,
      quickFaq: quickFaq$ | async,
      isSomeItemsSelected: isSomeItemsSelected$ | async
    };
    as data
  ) {
    <div class="right-sidebar-container">
      @if (data.panels && isPanelOpen(data.panels)) {
        <div
          class="right-sidebar--panel-wrapper flex fx-column"
          [ngClass]="currentPanel(data.panels) + '-panel'"
        >
          @if (!data.panels.includes(PanelEnum.TRADES) || data.user) {
            <div class="panel-header flex fx-align-center fx-justify-between">
              <div class="panel-header__title tw-font-bold">{{ 'P2P_WIDGETS.' + currentPanel(data.panels) | uppercase | translate }}</div>
              <button
                class="tw-block tw-bg-unset tw-text-gray-400 tw-border-none"
                (click)="closeAllPanels()"
                [class.lg:tw-hidden]="!data.panels.includes(PanelEnum.CHAT)"
                [class.lg:tw-block]="data.panels.includes(PanelEnum.CHAT)"
              >
                <mat-icon svgIcon="close"> </mat-icon>
              </button>
            </div>
          }
          <div class="panel-content">
            @if (data.panels && data.panels.length >= 0) {
              @switch (currentPanel(data.panels)) {
                @case (PanelEnum.CHAT) {
                  @if (data.panels.includes(PanelEnum.CHAT)) {
                    <app-chat-v2
                      (authAttempt)="openAuthModal()"
                      class="chat-panel"
                    ></app-chat-v2>
                  }
                }
                @case (PanelEnum.TRADES) {
                  @if (data.user !== undefined) {
                    @if (data.user) {
                      <app-p2p></app-p2p>
                    } @else {
                      <app-ui-trades-stub
                        (authEvent)="openAuthModal()"
                        (closePanelEvent)="closeAllPanels()"
                      >
                      </app-ui-trades-stub>
                    }
                  } @else {
                    <app-ui-spinner-loader> </app-ui-spinner-loader>
                  }
                }
                @default {}
              }
            }
          </div>
        </div>
      }
      @if (data.quickFaq && !data.isSomeItemsSelected) {
        <div class="right-sidebar--panel-wrapper flex fx-column absolute">
          <app-quick-faq
            [activeFaq]="data.quickFaq"
            (oncloseClick)="closeQuickFaq(data.quickFaq.url)"
          >
          </app-quick-faq>
        </div>
      }
    </div>
  }
}
