@if (modes.length) {
  <div
    class="main-control tw-cursor-pointer"
    (click)="open = !open"
  >
    <mat-icon [svgIcon]="'all-games'"> </mat-icon>
  </div>
} @else {
  <div class="loader">
    <app-ui-spinner-loader></app-ui-spinner-loader>
  </div>
}

@if (open) {
  <div
    class="wrapper"
    (clickOutside)="open = false"
  >
    <div class="main-content custom-scroll">
      <div class="content">
        @for (mode of modes; track mode) {
          @if (getMode(mode); as category) {
            <ui-category
              [title]="modesDesc[mode].title"
              [description]="modesDesc[mode].description"
            >
              @for (game of category; track game) {
                <ui-game-card
                  [cardNavigateUrl]="'/game/' + game.key"
                  [cardImgSrc]="getGameIconSrc(game)"
                  [cardHoverTitle]="game.title"
                  [titleType]="'bottom'"
                  (click)="closeMenu()"
                >
                </ui-game-card>
              }
            </ui-category>
          }
        }
      </div>
      <div
        class="promo-content"
        *hasPermission="[permissions.MEMBER, permissions.CAN_USE]"
      >
        <ui-category [title]="'Freecoins'">
          @for (item of promoCategory; track item) {
            <ui-any-icon-card
              [class]="item.label"
              [icon]="item.icon"
              [title]="item.label"
              [titleType]="'bottom'"
              (click)="item.callback && item.callback()"
            >
            </ui-any-icon-card>
          }
        </ui-category>
      </div>
    </div>
    <div class="bottom-content">
      <div
        class="button tw-cursor-pointer"
        (click)="open = !open"
      >
        <mat-icon
          class="close-btn"
          [svgIcon]="'menu-close'"
        >
        </mat-icon>
      </div>
      @if (lastGames && lastGames.length) {
        <div class="last-games">
          <ng-scrollbar
            track="horizontal"
            class="last-games--mobile"
          >
            <div class="games">
              @for (game of lastGames; track game) {
                <ui-game-card
                  [cardNavigateUrl]="'/game/' + game.key"
                  [cardImgSrc]="getGameIconSrc(game)"
                  [cardHoverTitle]="game.title"
                ></ui-game-card>
              }
            </div>
          </ng-scrollbar>
        </div>
      }
    </div>
  </div>
}

@if (openMobile) {
  <div
    class="mobile"
    (clickOutside)="openMobile = false"
  >
    <app-ui-mobile-wrapper (closeWrapper)="onSwipe()">
      <app-promocode-mobile></app-promocode-mobile>
    </app-ui-mobile-wrapper>
  </div>
}
