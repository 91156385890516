@if (OrderStatusEnum.WAIT_FOR_TRADE === status()) {
  <div class="tw-font-medium tw-text-xl tw-mb-4 tw-text-gray-100">{{ 'P2P_WIDGETS.SIH_TRADE.TRADE_PROCESSING.HEADER' | translate }}</div>
  <div class="tw-font-normal tw-text-sm tw-mb-6 tw-text-gray-300 tw-inline-flex tw-gap-1 tw-flex-wrap">
    {{ 'P2P_WIDGETS.SIH_TRADE.TRADE_PROCESSING.BODY1' | translate }}
    <a
      [href]="sihLink"
      target="_blank"
      class="tw-text-blue-500 tw-underline"
      >{{ 'SIH.REFILL.SIH' | translate }}</a
    >
    {{ 'P2P_WIDGETS.SIH_TRADE.TRADE_PROCESSING.BODY2' | translate }}
  </div>
  <div
    class="tw-bg-gray-550 tw-rounded-xl tw-w-full tw-h-44 tw-flex tw-items-center tw-justify-center"
    @expansionAnimation
  >
    <mat-icon
      class="loading !tw-w-20 !tw-h-20 tw-text-gray-400"
      svgIcon="refresh-line"
    />
  </div>
} @else {
  <div class="tw-font-medium tw-text-xl tw-mb-4">{{ 'P2P_WIDGETS.SIH_TRADE.TRADE_PROCESSING.ERROR_HEADER' | translate }}</div>
  <!-- <div class="tw-font-normal tw-text-sm tw-mb-6 tw-text-gray-300">
    {{ 'Описание ошибки' | translate }}
  </div> -->
  <div
    class="tw-bg-gray-550 tw-rounded-xl tw-w-full tw-h-44 tw-flex tw-items-center tw-justify-center tw-mb-6"
    @expansionAnimation
  >
    <mat-icon
      class="!tw-w-20 !tw-h-20 tw-text-red-500"
      svgIcon="warning"
    />
  </div>
  <div class="trade-actions tw-grid tw-gap-3">
    <button
      class="solve-problem btn btn-primary--design btn-fill tw-py-3 tw-px-6 !tw-rounded-xl"
      (click)="solveProblem.emit()"
    >
      {{ 'P2P_WIDGETS.SIH_TRADE.TRADE_PROCESSING.BUTTON_ERROR1' | translate }}
    </button>

    <button
      class="close-trade btn btn-basic--design tw-px-6 tw-py-3 !tw-rounded-xl"
      (click)="closeModal.emit()"
    >
      {{ 'P2P_WIDGETS.DEPOSIT_MODIFIER_DIALOG.CANCEL_BUTTON' | translate }}
    </button>
    <button
      class="manual-trade btn btn-default--design tw-px-6 tw-py-3 !tw-rounded-xl tw-w-full"
      (click)="createTradeManually.emit()"
    >
      {{ 'P2P_WIDGETS.SIH_TRADE.TRADE_PROCESSING.BUTTON_ERROR2' | translate }}
    </button>
  </div>
}
