@if (
  {
    user: user$ | async,
    messages: messages$ | async
  };
  as data
) {
  <div class="chat tw-flex tw-flex-col tw-w-full tw-h-full tw-relative tw-pt-4">
    <ng-scrollbar>
      <div class="tw-flex tw-flex-col tw-gap-4 tw-p-5">
        @for (message of data.messages; track message.id) {
          <app-chat-item
            [message]="message"
            [isMyMessage]="isMyMessage(message, data.user)"
          ></app-chat-item>
        }
      </div>
    </ng-scrollbar>
    <div class="chat-footer tw-flex tw-justify-center">
      @if (data.user) {
        <app-message-input-v2 (addMessage)="sendMsg($event)"></app-message-input-v2>
      } @else {
        <a
          class="chat-footer__not-auth tw-m-auto tw-underline"
          (click)="authAttempt.emit()"
        >
          {{ 'LOGIN' | translate }}
        </a>
      }
    </div>
  </div>
}
