import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import {
  BalanceWidgetComponentModule,
  BrandComponentModule,
  SettingsMenuComponentModule,
  SpinnerLoaderSAComponent,
} from '@dev-fast/ui-components';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { DesktopAppActionMenuModule } from '@app/desktop-app/action-menu';
import { SharedComponentsModule } from '@app/shared/components';
import { HasPermissionModule, IsProjectModule, LangRouterModule, ModuleIsOnModule, StopPropagationModule } from '@app/shared/directives';
import { AppCurrencyModule, AppCurrencyTranslateModule, CustomAsyncPipeModule } from '@app/shared/pipes';
import { WidgetsNotificationsNewModule } from '@app/widgets/notifications-new';

import { BalanceComponent } from './balance/balance.component';
import { DesktopHeaderV2Component } from './desktop-header.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    BrandComponentModule,
    SettingsMenuComponentModule,
    BalanceWidgetComponentModule,
    TranslateModule,
    RouterModule,
    MatSelectModule,
    FormsModule,
    MatProgressSpinnerModule,
    SharedComponentsModule,
    ReactiveFormsModule,
    AppCurrencyModule,
    HasPermissionModule,
    StopPropagationModule,
    WidgetsNotificationsNewModule,
    DesktopAppActionMenuModule,
    ModuleIsOnModule,
    LangRouterModule,
    IsProjectModule,
    CustomAsyncPipeModule,
    CurrencyComponent,
    AppCurrencyTranslateModule,
    SpinnerLoaderSAComponent,
  ],
  declarations: [DesktopHeaderV2Component, BalanceComponent],
  exports: [DesktopHeaderV2Component],
})
export class DesktopAppHeaderNewModule {}
