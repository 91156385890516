@if (icon) {
  <div class="icon">
    <mat-icon [svgIcon]="icon"> </mat-icon>
  </div>
}
@if (title) {
  <div class="title">
    {{ title }}
  </div>
}
