<!-- Что рефачить:
1) Есть блок с "настройте стим, бан" и т.д. он минимум используется в трех местах: в п2п, в модалке скина, здесь
2) Почему бы не вынести в отдельный компонент доп настройки
3) Заменить прямую передачу цвета в слайдер на функцию getSellSpeed-->
<div class="lotSetupPanel tw-flex tw-flex-col tw-overflow-hidden tw-fixed tw-top-0 tw-right-0 tw-h-screen">
  <div class="lotSetupPanel-title tw-flex tw-items-center tw-justify-between tw-pb-8 tw-pr-5 tw-font-bold tw-text-base">
    <div>{{ 'P2P_WIDGETS.DEPOSITING.SIDE_HEADER' | translate }}</div>
    <div
      class="close-btn tw-cursor-pointer"
      (click)="onPanelClose()"
    >
      <mat-icon svgIcon="close" />
    </div>
  </div>
  <app-p2p-sell-adviser
    class="tw-pr-5 tw-pb-6"
    [selected]="!!selectedItems()"
    [selectedSum]="selectedSum"
    (goToSteamInventoryMarketTab)="goToInventoryMarketTab()"
    (reqAutoSelect)="updateAutoSelected($event)"
  />
  @if (lotDepositSettings) {
    <ng-scrollbar class="lotSetupPanel-content hide-scroll tw-h-full">
      <div class="tw-flex tw-flex-col tw-pr-5 tw-w-full">
        @for (entity of itemsForSale(); track $index) {
          <app-p2p-sell-setup-item
            class="tw-mb-2"
            [item]="entity"
            [duration]="lotDepositSettings.tradesDuration"
            (itemRemoved)="removeSelectedItem(entity)"
          />
        }
        <div
          class="deleteAllBtn tw-cursor-pointer tw-mt-4"
          (click)="onPanelClose()"
        >
          {{ 'P2P_WIDGETS.DEPOSITING.DELETE_ALL' | translate }}
        </div>
      </div>
    </ng-scrollbar>
  }
  <div class="lotSettings tw-pt-6 tw-pr-5">
    @if (permissions?.success && permissions?.canTrade?.success && permissions?.canSteamAPI?.success) {
      @if (currentOverprice !== null) {
        <div
          class="lotSettings-priceSlider tw-flex tw-flex-col tw-gap-3"
          [formGroup]="sellSettingsForm"
        >
          <mat-slider
            class="p2p-setup-slider"
            discrete
            [displayWith]="formatLabel"
            [min]="-30"
            [max]="100"
            [step]="1"
            [style.--mdc-slider-label-label-text-color]="currentOverpriceColor"
          >
            <input
              matSliderThumb
              formControlName="overprice"
            />
          </mat-slider>
          <div class="lotSettings-priceSlider-tips tw-flex tw-justify-between">
            <div>{{ 'P2P_WIDGETS.DEPOSITING.SELLING_SPEED' | translate }}</div>
            @switch (true) {
              @case (currentOverprice > 30) {
                <div>{{ 'P2P_WIDGETS.DEPOSITING.VERY_SLOW' | translate }}</div>
              }
              @case (currentOverprice > 10) {
                <div>{{ 'P2P_WIDGETS.DEPOSITING.SLOW' | translate }}</div>
              }
              @case (currentOverprice < -3) {
                <div>{{ 'P2P_WIDGETS.DEPOSITING.QUICK_SALE' | translate }}</div>
              }
              @default {
                <div>{{ 'P2P_WIDGETS.DEPOSITING.RECOMMENDED' | translate }}</div>
              }
            }
          </div>
        </div>
      }
      <button
        class="btn btn-primary--design lotSettings-sellBtn tw-w-full"
        (click)="sellSelectedItems()"
      >
        {{ 'P2P_WIDGETS.DEPOSITING.SALE_FOR' | translate }}
        @if (selectedSumWithOverprice) {
          {{ selectedSumWithOverprice | appCurrency }}
        }
      </button>
      <app-p2p-sell-additional-settings
        [lotDepositSettings]="lotDepositSettings"
        (settingsChanged)="updateTradeSettings($event)"
      />
    } @else {
      <div class="tw-flex tw-flex-col">
        <span class="tooltip tw-font-normal"> {{ permissionErrorTooltip | translate }} </span>
        <button
          class="btn btn-primary--design lotSettings-sellBtn tw-w-full"
          (click)="handleErrorAction(permissions)"
          [disabled]="isActionBtnDisabled"
        >
          {{ permissionErrorBtnText | translate: { days: permissions?.canTrade?.days || 0 } | uppercase }}
        </button>
      </div>
    }
  </div>
</div>
