import { inject, Injectable, signal } from '@angular/core';
import type { INotification } from '@dev-fast/types';
import type { Observable } from 'rxjs';
import { filter, tap } from 'rxjs';

import { NotificationsService } from '@app/core/notification-service';

import { NOTIFY_DURATION } from './constants';
import { Timer } from './utils/timer';

const STACK_SIZE = 4;
const STACK_FROM: 'top' | 'bottom' = 'bottom';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  readonly #notificationsService = inject(NotificationsService);

  #notificationsStack: Timer[] = [];
  showcaseNotifications = signal<INotification[]>([]);
  #isActivePage = signal(true);

  constructor() {
    this.#watchAddNotification.subscribe();
  }

  get #watchAddNotification(): Observable<INotification> {
    return this.#notificationsService.isAddNotificationSuccess$.pipe(
      filter((val: INotification) => !!val?.showToast),
      tap((val) => {
        this.addNotification(val);
      }),
    );
  }

  addNotification(notification: INotification): void {
    this.#updateNotifications(notification);

    this.#notificationsStack.push(
      new Timer(notification.id, this.#isActivePage(), () => this.#popNotification(notification.id), NOTIFY_DURATION),
    );
  }

  deleteNotification(notifyId: number): void {
    this.#notificationsService.removeNotification(notifyId);
    this.showcaseNotifications.update((val) => val.filter((oldNotification) => oldNotification.id !== notifyId));
  }

  pauseTimer(): void {
    this.#notificationsStack.forEach((t: Timer) => t.pause());
  }

  resumeTimer(): void {
    this.#notificationsStack.forEach((t: Timer) => t.resume());
  }

  activePageChange(isActive: boolean): void {
    this.#isActivePage.set(isActive);
    if (isActive) {
      this.resumeTimer();
    } else {
      this.pauseTimer();
    }
  }

  #updateNotifications(notification: INotification): void {
    const isTop = STACK_FROM === 'top';
    const isBottom = STACK_FROM === 'bottom';

    this.showcaseNotifications.update((notifications: INotification[]) => {
      const isNotificationSize = notifications.length > STACK_SIZE;

      if (isTop) {
        notifications.unshift(notification);
      }

      if (isTop && isNotificationSize) {
        notifications.pop();
      }

      if (isBottom) {
        notifications.push({ ...notification, remainingTime: NOTIFY_DURATION });
      }

      if (isBottom && isNotificationSize) {
        notifications.shift();
      }

      return notifications;
    });
  }

  #popNotification(id: number): void {
    this.showcaseNotifications.update((val) => val.filter((oldNotification) => oldNotification.id !== id));
  }
}
