import type { IDailyQuestEventConfig } from '@dev-fast/types';

/** Массив значений для заполнения прогресс бара в карточке кейса ивента Age of Dragons */
export const AGE_OF_DRAGONS_PROGRESS_BAR_GRADIENT = [
  {
    stopColor: '#FF8933',
  },
  {
    offset: 0.8314,
    stopColor: '#CF3808',
  },
  {
    offset: 1,
    stopColor: '#E94713',
  },
];

/** Настройки для ивента с ежедневными заданиями daily-quest-event-config */
export const DailyQuestEventConfig: IDailyQuestEventConfig = {
  // ------ Общие настройки ивента ---------------- ||
  eventNameLocale: 'Dragons',
  eventLogoIconSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-1.png',
  eventLogoSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/badge.webp',
  checkMarkIconSrc: 'assets/img/ny-event/summer-event/success.svg',
  homePageBackgroundColor: 'var(--main-color-background)',
  homePageBottomBannerSrc: 'url(https://d2lomvz2jrw9ac.cloudfront.net/dragons/banner_secretprize.png)',
  //  ------ Настройки табов навигации ------------ ||
  navigationTabBg: '#121719',
  navigationTabAccentColor: '#c24600',
  navigationTabAccentActiveColor: '#ff5c00',
  navigationTabFirstImg: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/super-eggs.png',
  navigationTabSecondImg: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/winner.png',
  //  ------ Настройки баннера -------------------- ||
  bannerImgSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/background.webp',
  bannerSubImgSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/sparkles.gif',
  bannerLeftImgSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/man.webp',
  bannerRightImgSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/dragon.webp',
  // -----  Настройки карточки кейсов ивента ------ ||
  eventCaseProgressBarGradient: AGE_OF_DRAGONS_PROGRESS_BAR_GRADIENT,
  eventCaseCheckMarkIconSrc: 'assets/img/ny-event/summer-event/success.svg',
  eventCaseMainPrizeSrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/golden.webp',
  // ------ Настройки валюты -----------------------||
  bronzeCurrencySrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-4.png',
  silverCurrencySrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-3.png',
  goldCurrencySrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-2.png',
  currencyMap: {
    /** bronze currency */
    'orange-key': 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-4.png',
    /** silver currency */
    'green-key': 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-3.png',
    /** gold currency */
    'blue-key': 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coin-dragon-2.png',
  },
  // ------ Настройки ежедневных квестов ---------- ||
  dailyProgressFillColor: '#FA8200',
  // ------ Настройки еженедельных квестов -------- ||
  weeklyProgressFillColor: '#599aff',
  weeklyFinalQuestProgressFillColor: '#fac106',
  weeklyQuestBgColor: '#599aff',
  weeklyQuestActiveBgColor: '#8bb9ff',
  weeklyQuestFinalBgColor: '#fac106',
  //  ------ Настройки FAQ ------------------------ ||
  questGuideAllCurrencySrc: 'https://d2lomvz2jrw9ac.cloudfront.net/dragons/eggs/coins.png',
  questGuideDailyDescription: [
    'LOCAL.NY_EVENT.EVENT_MODAL.DAILY_1',
    'LOCAL.NY_EVENT.EVENT_MODAL.DAILY_2',
    'LOCAL.NY_EVENT.EVENT_MODAL.DAILY_3',
  ],
  questGuideWeeklyDescription: [
    'LOCAL.NY_EVENT.EVENT_MODAL.WEEKLY_1',
    'LOCAL.NY_EVENT.EVENT_MODAL.WEEKLY_2',
    'LOCAL.NY_EVENT.EVENT_MODAL.WEEKLY_3',
    'LOCAL.NY_EVENT.EVENT_MODAL.WEEKLY_4',
  ],
  questGuideSpecialDescription: ['LOCAL.NY_EVENT.EVENT_MODAL.SPECIAL_1', 'LOCAL.NY_EVENT.EVENT_MODAL.SPECIAL_2'],
};
