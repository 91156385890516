@if (
  {
    gameSelectorOpened: gameSelectorOpened$ | async,
    breakpoints: breakpoints$ | async,
    counts: counts$ | async,
    marketCounts: marketCounts$ | async,
    online: online$ | async,
    panels: panels$ | async,
    menu: menu$ | async,
    availableGames: dictAvailableGames$ | async,
    lastGames: lastGames$ | async
  };
  as data
) {
  <div
    class="layout-wrapper"
    [ngClass]="{
      'right-sidebar--opened': isOpenRightPanel(data.panels),
      faded: fadeSides$ | async
    }"
  >
    <div
      #headerAnchor
      class="header"
    >
      <ui-header></ui-header>
    </div>
    <div class="layout-content-wrapper">
      @if (showGameMenu()) {
        <div class="left-sidebar">
          <ui-desktop-game-menu [availableGames]="data.availableGames"></ui-desktop-game-menu>
        </div>
      }
      <div class="layout-content">
        <div
          class="page-content"
          (resized)="onResize($event)"
        >
          <app-iframe>
            @if (sectionHeaderPortal$ | async; as sectionHeaderPortal) {
              <div class="portal-section-header-outlet">
                <ng-template [cdkPortalOutlet]="sectionHeaderPortal"></ng-template>
              </div>
            }
            <div class="content-outlet">
              <router-outlet></router-outlet>
              <app-recent-activities></app-recent-activities>
            </div>
          </app-iframe>
        </div>
        <div class="footer">
          <app-ui-new-footer
            [online]="data.online"
            [counts]="data.counts"
            [marketCounts]="data.marketCounts?.marketCounts"
            [availableGames]="data.availableGames"
            (openLangPanel)="openLangMenu()"
            (openChat)="openTechSupportDialog()"
          ></app-ui-new-footer>
        </div>
      </div>
      <div class="right-sidebar">
        <app-desktop-right-sidebar-new></app-desktop-right-sidebar-new>
      </div>
    </div>
    <div
      class="fade-overlay"
      (click)="onClickLockedSide()"
    ></div>
    <app-bottom-control-panel [panelClass]="bottomControlClass$ | myAsync">
      <ui-mobile-game-menu
        left-controls
        class="desktop-hide"
        [availableGames]="data.availableGames"
        [lastGames]="data.lastGames"
        (openGleamModal)="onOpenGleamModal()"
      >
      </ui-mobile-game-menu>
      @if ((curLegacyGame$ | async) === 'fast') {
        <app-participation-panel></app-participation-panel>
      }
      <app-right-controls
        [items]="rightControls"
        right-controls
      ></app-right-controls>
      @if (showBottomBaner()) {
        <app-refill-banner bottom-banner></app-refill-banner>
      }
    </app-bottom-control-panel>
    @if (!isServer) {
      <app-notification></app-notification>
    }
  </div>
  <ng-template #modalTmpl>
    <router-outlet name="modal"></router-outlet>
  </ng-template>
}
