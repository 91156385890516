import { DOCUMENT } from '@angular/common';
import { Component, computed, HostListener, inject, signal } from '@angular/core';
import type { INotification } from '@dev-fast/types';

import { ToastService } from '../toast.service';
import { notificationSlideLeftAnimation } from './notification.animation';
import { NotificationItemComponent } from './notification-item/notification-item.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [NotificationItemComponent],
  animations: [notificationSlideLeftAnimation],
})
export class NotificationComponent {
  readonly #notificationService = inject(ToastService);
  #document = inject<Document>(DOCUMENT);

  readonly isPaused = signal(false);
  readonly notifications = computed<INotification[]>(() => this.#notificationService.showcaseNotifications());

  @HostListener('document:visibilitychange', ['$event'])
  handleVisibilityChange(): void {
    const isActive = !this.#document.hidden;
    this.isPaused.set(!isActive);
    this.#notificationService.activePageChange(isActive);
  }

  pauseNotifications(): void {
    if (this.isPaused()) {
      return;
    }
    this.isPaused.set(true);
    this.#notificationService.pauseTimer();
  }

  resumeNotifications(): void {
    if (!this.isPaused()) {
      return;
    }
    this.isPaused.set(false);
    this.#notificationService.resumeTimer();
  }
}
