import { inject, Injectable } from '@angular/core';
import type {
  ActivePanel,
  IInventoryItem,
  IInventoryShortInfo,
  ILocales,
  IUserDetailed,
  LegacyGameConfig,
  LegacyGameState,
  LegacyLiteGameState,
  NewMenu,
  NewPanel,
} from '@dev-fast/types';
import { Panel } from '@dev-fast/types';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, Select } from '@ngxs/store';
import type { ActionsExecuting } from '@ngxs-labs/actions-executing';
import { actionsExecuting } from '@ngxs-labs/actions-executing';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import type { Observable } from 'rxjs';

import { AuthService } from '@app/auth';
import type { SetLanguage } from '@app/core/language-service';
import { LanguageService } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { AccountState } from '@app/core/state/account';
import { GamesState } from '@app/core/state/games-store';
import { ChangeParamsShop, GetInventoryInfo, GetShopItems, InventoryState } from '@app/core/state/inventory';
import { CloseAllMenu, CloseMenu, LayoutState, NavigateToMobile, OpenMenu } from '@app/core/state/layout';
import { CommonStoreService } from '@app/core-state-common';

@Injectable()
export class DesktopAppActionMenuService {
  readonly commonService = inject(CommonStoreService);
  readonly authService = inject(AuthService);
  readonly notificationsService = inject(NotificationsService);
  readonly actions$ = inject(Actions);
  readonly languageService = inject(LanguageService);

  user$: Observable<IUserDetailed | null | undefined> = this.commonService.user$;
  currentLocale$: Observable<ILocales> = this.languageService.currentLocale$;

  @Select(actionsExecuting([GetShopItems, ChangeParamsShop]))
    inventoryShopLoading$!: Observable<ActionsExecuting>;

  @Select(InventoryState.inventorySum)
    totalBalance$!: Observable<number>;

  @Select(InventoryState.inventoryShortInfo)
    inventoryShortInfo$!: Observable<IInventoryShortInfo>;

  @Select(GamesState.legacyConfig)
    legacyConfig$!: Observable<LegacyGameConfig | null>;

  @Select(GamesState.legacyState)
    legacyState$!: Observable<LegacyGameState | null>;

  @Select(GamesState.legacyLiteState)
    legacyLiteState$!: Observable<LegacyLiteGameState | null>;

  @Select(GamesState.legacyParticipation)
    legacyParticipation$!: Observable<IInventoryItem[]>;

  @Select(AccountState.canLevelUp)
    canLevelUp$!: Observable<boolean>;

  @Select(LayoutState.lockedPanels)
    lockedPanels$!: Observable<Panel[]>;

  @Select(LayoutState.activeMenu)
    activeMenu$!: Observable<NewMenu[] | null>;

  @Select(LayoutState.activePanelNew)
    panels$!: Observable<NewPanel[]>;

  changeActivePanel = (activePanel: ActivePanel | null): void => {
    this.commonService.changeActivePanel(activePanel);
    if (activePanel?.panel === Panel.MAIN_MENU) {
      this.getInventoryInfo();
    }
  };

  login = (): void => this.authService.openLoginProvidersModal();
  logout = (): void => this.authService.onLogout();
  switchLang = (locale: ILocales): SetLanguage => this.languageService.setLanguage(locale);

  @Dispatch() getInventoryInfo = (): GetInventoryInfo => new GetInventoryInfo();
  @Dispatch() navigateToMobile = (): NavigateToMobile => new NavigateToMobile();
  @Dispatch() navigate = (path: any[]): Navigate => new Navigate(path);
  @Dispatch() openMenu = (menu: NewMenu): OpenMenu => new OpenMenu(menu);
  @Dispatch() closeMenu = (menu: NewMenu): CloseMenu => new CloseMenu(menu);
  @Dispatch() closeAllMenu = (): CloseAllMenu => new CloseAllMenu();
}
