<div class="header">
  @if (title) {
    <div class="title">
      {{ title }}
    </div>
  }
  @if (description) {
    <div class="description">
      {{ description }}
    </div>
  }
</div>
<div class="items">
  <ng-content></ng-content>
</div>
